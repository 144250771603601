
import { defineComponent, onMounted } from "@vue/runtime-core";
import { ref } from "vue";
import tiempo from "@/components/metodos/tiempo.vue"

import archivero from "@/store"
import { useRoute, useRouter } from "vue-router";

export default defineComponent({

name:"cobros",


setup(){

const cobros = ref()
const cobros2 = ref()
const spin = ref(false)
const estado = ref(false)
const id = ref()
const totalhoy = ref("0.00")
const totalotrosdias = ref("0.00")

const fecha1 = ref()
const fecha2 = ref()
const {hoy} = tiempo
fecha1.value = hoy.value
fecha2.value = hoy.value


const obtenercobros = async () =>{
const parametros = {
body:JSON.stringify({fecha1:fecha1.value,fecha2:fecha2.value}),
headers:{"content-type":"application/json"},
method: "post"
}

const consulta = await fetch("http://"+archivero.state.url+"/sg.php?ac=cobrospordia",parametros)
const respuesta = await consulta.json() 
const Respuesta = Object.values(respuesta)

const estado = consulta.statusText.toString()
if(estado=='OK'){
cobros.value = Respuesta
cobros2.value = cobros.value

totalhoy.value = (cobros2.value.filter((i: typeof cobros2.value)=> 
i.ventasfecha==fecha1.value || i.ventasfecha==fecha2.value )
.map((e: typeof cobros2.value)=>{
return e.monto
}).reduce((anterior:number, actual:number)=>{
return (Number(anterior)+Number(actual))
},0)).toFixed(2)


totalotrosdias.value = (cobros2.value.filter((i: typeof cobros2.value)=> 
i.ventasfecha!==fecha1.value && i.ventasfecha!==fecha2.value )
.map((e: typeof cobros2.value)=>{
return e.monto
}).reduce((anterior:number, actual:number)=>{
return (Number(anterior)+Number(actual))
},0)).toFixed(2)

}
}


const cobrospaginados = ref()

const Obtenercobros = ()=>{
cobrospaginados.value=[]
obtenercobros().then(()=>{
cobrospaginados.value=  cobros.value.filter((elemento: any)=>cobros.value.indexOf(elemento)<10)
})
}

onMounted( async()=>{


Obtenercobros()


})

const rutas = useRouter()
const ruta = useRoute()

const abrirdocumento = async(e: string,t: string) =>{
async function primera(){
archivero.commit({
  type: 'Documento',
  elemento: e
})
}
  
primera().then(()=>{

 rutas.push({
        name: 'NuevaVenta',params:{tipo:t}
      }) 

})



}
const cambiarpaginacion = (index: number) =>{
  cobrospaginados.value=  cobros.value.filter((elemento: any)=>
  cobros.value.indexOf(elemento)>=10*(index) && cobros.value.indexOf(elemento)<10*(index+1))

}




const cambiarspin = () =>{
spin.value = !spin.value

}




const busqueda = ref()
const buscar = () => {
cobros.value = cobros2.value.filter((elemento: any)=>(elemento.descripcion.toUpperCase()).match(busqueda.value.toUpperCase()) || (elemento.codigo.toUpperCase()).match(busqueda.value.toUpperCase()))
cobrospaginados.value=  cobros.value.filter((elemento: any)=>cobros.value.indexOf(elemento)<10)




}



return{
abrirdocumento,
fecha1,fecha2,
cambiarpaginacion,
cobrospaginados,
spin,
cambiarspin,
cobros,
estado,
totalhoy,
totalotrosdias,
Obtenercobros,
id,
buscar,
busqueda,
}
}

})
