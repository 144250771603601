import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, withCtx as _withCtx, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "margensuperior" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Menu = _resolveComponent("Menu")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Menu, { class: "no" }),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_router_view, null, {
        default: _withCtx(({Component}) => [
          (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
        ]),
        _: 1
      })
    ])
  ], 64))
}