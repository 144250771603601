
import { defineComponent, getCurrentInstance, inject} from "@vue/runtime-core";
import { ref } from "vue";
import obtener from "@/components/metodos/obtener.vue"

export default defineComponent({
name: "Buscar proveedor",
props:{
estado:Boolean
},
emits:{
cambiarestado : Function,

},
setup(){
const Obtenerproveedor = inject('Obtenerproveedor')

const proveedores = ref([])
const busqueda = ref()
const {proveedornombre} = obtener
const obtenerproveedor = () =>{
const respuesta = proveedornombre(busqueda.value) 
respuesta.then((respuesta: typeof proveedores.value)=>{
proveedores.value = respuesta

})

}

const instancia = getCurrentInstance()
const formularioproveedor = () =>{
instancia?.parent?.exposed!["cambiarestadoformularioproveedor"]()

}

return{


obtenerproveedor,
busqueda,
proveedores,
Obtenerproveedor,
formularioproveedor,
}
}

})
