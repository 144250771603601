
import { defineComponent, onMounted } from "@vue/runtime-core";
import { ref } from "vue";
import tiempo from "@/components/metodos/tiempo.vue"
import formulariocorte from "@/components/metodos/formulariodecorte.vue"
import Corte from "@/components/metodos/clases.vue"

export default defineComponent({

name:"cobros",
components:{
Formulariocorte: formulariocorte
},

setup(){

const cobros = ref()
const corteacumulado = ref("0.00")

const spin = ref(false)
const estado = ref(false)
const totalcobrado = ref("0.00")
const totalcobradohoy = ref("0.00")
const totalanteriores = ref("0.00")
const hoyacumulado = ref("0.00")
const anterioresacumulado = ref("0.00")
const totalventa = ref("0.00")
const pendientes = ref("0.00")
const ventaacumulada = ref("0.00")
const fecha1 = ref()
const  fecha2 = ref()

onMounted( async()=>{
const {hoy} = tiempo
fecha1.value = hoy.value
fecha2.value = hoy.value

const corte = new Corte(hoy.value, hoy.value)
corte.obtenercobros().then((Retorno:any)=>{
totalcobradohoy.value= Retorno.totalcobradohoy
totalanteriores.value= Retorno.totalanteriores
corteacumulado.value = Retorno.corteacumulado
anterioresacumulado.value = Retorno.anterioresacumulado
ventaacumulada.value = Retorno.ventaacumulada
hoyacumulado.value = Retorno.hoyacumulado
totalcobrado.value= Retorno.totalcobrado
totalventa.value = Retorno.totalventa
pendientes.value = Retorno.pendientes


})


const internet = window.navigator.onLine
setInterval(()=>{
if(internet){
const corte = new Corte(hoy.value, hoy.value)
corte.obtenercobros().then((Retorno:any)=>{
totalcobradohoy.value= Retorno.totalcobradohoy
totalanteriores.value= Retorno.totalanteriores
corteacumulado.value = Retorno.corteacumulado
anterioresacumulado.value = Retorno.anterioresacumulado
ventaacumulada.value = Retorno.ventaacumulada
hoyacumulado.value = Retorno.hoyacumulado
totalcobrado.value= Retorno.totalcobrado
totalventa.value = Retorno.totalventa
pendientes.value = Retorno.pendientes

})


}else{
    console.log("Sin conexión.")
}
},60000)



})


const cambiarspin = () =>{
spin.value = !spin.value
}

const cambiarestado = ():void =>{
estado.value = !estado.value
if(estado.value==false){

const corte = new Corte(fecha1.value, fecha2.value)
corte.obtenercobros().then((Retorno:any)=>{
totalcobradohoy.value= Retorno.totalcobradohoy
totalanteriores.value= Retorno.totalanteriores
corteacumulado.value = Retorno.corteacumulado
anterioresacumulado.value = Retorno.anterioresacumulado
ventaacumulada.value = Retorno.ventaacumulada
hoyacumulado.value = Retorno.hoyacumulado
totalcobrado.value= Retorno.totalcobrado
totalventa.value = Retorno.totalventa
pendientes.value = Retorno.pendientes

})

}
}







return{


ventaacumulada,
anterioresacumulado,
imprimir: () => {
  window.print();
},
hoyacumulado,
corteacumulado,
totalcobrado,
fecha1,fecha2,
pendientes,
totalventa,
cobros,
totalcobradohoy,
totalanteriores,
estado,
cambiarestado,

spin,
cambiarspin,

}
}

})
