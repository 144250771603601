
import { computed, defineComponent, nextTick, onBeforeMount, onMounted, SetupContext, toRefs, watchEffect } from "@vue/runtime-core";
import {  onUpdated, provide, reactive, Ref, ref } from "vue";
import obtener from "@/components/metodos/obtener.vue"
import Buscarproducto from "@/components/metodos/formulariobuscarproducto.vue";
import archivero from "@/store"
import Cabecera from "@/components/metodos/cabecera.vue"
import Formulariocliente from "@/components/metodos/formulariocliente.vue"
import Pie from "@/components/metodos/pie.vue"
import { useRoute, useRouter} from 'vue-router'
import Cobros from "@/components/metodos/formulariocobros.vue";
import Swal from "sweetalert2";
import Buscarcliente from "./metodos/buscarcliente.vue";
import tiempo from "./metodos/tiempo.vue"

const __default__ = defineComponent({
components: { Buscarproducto,Cobros,Buscarcliente, Cabecera, Pie, Formulariocliente},


setup(_props, context:SetupContext){
const borrador = ref(0)
const rutas = useRouter()
const estadonotadeventa = ref(0)
const ruta = useRoute()
const tipotitulo = ref("")
const titulo = ()=>{
const parametrotipo = ruta.params.tipo

switch(parametrotipo?.toString()){

case "p":
  tipotitulo.value="PRESUPUESTO"
  break;


case "n":
  tipotitulo.value="NOTA DE VENTA"
  break;


case "f":
  tipotitulo.value="FACTURA"
  break;
}
}
const {hoy} = tiempo
const tipo = ref()
const clientertn = ref()
const fecha1 = ref()
const fecha2 = ref()
const fechadecredito = ref(hoy.value)
const recargo = ref()
const Cobros = ref()
const saldo = ref()
const clientes = ref()
onBeforeMount(()=>{
  fecha1.value=hoy.value
fecha2.value=hoy.value
})
//Obtener notas
const obtenercxc = async (): Promise<unknown | undefined> =>{

archivero.commit({
  type:"Parametros",
  fecha1: fecha1.value,
  fecha2: fecha2.value,  
})
const parametros = {
body:JSON.stringify({fecha1:fecha1.value,fecha2:fecha2.value}),
headers:{"content-type":"application/json"},
method: "post"
}

const consulta = await fetch("http://"+archivero.state.url+"/sg.php?ac=notasdeventacxc",parametros)
const respuesta = await consulta.json()

clientes.value=respuesta
if(consulta.statusText=="OK"){
return Promise.resolve(1)
}else{
  return Promise.resolve(0)
}


} 


const actualizartotal = () =>{
const array = clientes.value.filter((e:any)=>e.cliente.match(cliente.value.toUpperCase())).map((elemento:any)=>{
  return elemento["total"]
})

saldo.value= (Number(array.reduce((anterior:unknown,actual:unknown)=>{
return Number(anterior)+Number(actual)
},0))-Number(Cobros.value)).toFixed(2)


}
const MetodoCobros = async (): Promise<number | undefined> =>{
const parametros = {
headers:{"content-type":"application/json"},
body:JSON.stringify({fecha1: fecha1.value, fecha2: fecha2.value}),
method:"post"}
const consulta = await fetch("http://"+archivero.state.url+"/sg.php?ac=cobrospordiayventacxc",parametros)
const respuesta = await consulta.json()
Cobros.value = (respuesta.filter((elemento: any)=>{
return elemento.cliente.match(cliente.value.toUpperCase()) && elemento.estado<3
})).map((elemento:any)=>{
 return elemento["monto"]
}).reduce((anterior:any,actual:any)=>(Number(anterior)+Number(actual)).toString(),0)
if(consulta.statusText=="OK"){
  return Promise.resolve(1)
}
}
//Obtener notas
const MetodoBusquedaCliente = () =>{
MetodoCobros().then((e: any)=>{
if(e==1){
obtenercxc().then((e: any)=>{
if(e=="1"){
  actualizartotal()

}
})

}
})

}


const bloquearcredito = async () =>{

  const parametros = {
headers:{"content-type":"application/json"},
method:"post",
body:JSON.stringify({iddocumento:idrecibido.value})
}

const consulta = await fetch("https://api.utp.hn/sg.php?ac=consultarcredito",parametros)
const respuesta = await consulta.json()
if(respuesta.length>0){
  const html1 = document.getElementById('fechadecredito') as HTMLInputElement

const html2 = document.getElementById('recargo') as HTMLInputElement

html1.disabled=true
html2.disabled=true
}


}







onBeforeMount(()=>{
   titulo()
})
const estadoformulariocliente = ref(false)
const interruptor = ref(true)
const iddocumento = computed(()=>{
  return archivero.state.documento
})

const funcionlineas = () =>{
Limpiar()
const codigo = document.getElementById("codigo") as HTMLInputElement
codigo.focus()
total.value =
Number(lineas.value.map((e: typeof lineas.value)=>e.total).reduce((anterior:number,actual:number)=>
{return (Number(anterior)+Number(actual))},0)).toString()
subtotal.value =
Number(lineas.value.map((e: typeof lineas.value)=>e.subtotal).reduce((anterior:number,actual:number)=>
{return (Number(anterior)+Number(actual))},0)).toString()
isv.value =
Number(lineas.value.map((e: typeof lineas.value)=>e.isv).reduce((anterior:number,actual:number)=>
{return (Number(anterior)+Number(actual))},0)).toString()
cantidad.value =
Number(lineas.value.map((e: typeof lineas.value)=>e.cantidad).reduce((anterior:number,actual:number)=>
{return (Number(anterior)+Number(actual))},0)).toString()
descuento.value =
Number(lineas.value.map((e: typeof lineas.value)=>e.descuento).reduce((anterior:number,actual:number)=>
{return (Number(anterior)+Number(actual))},0)).toString()

}
const idrecibido = ref(0)

const montado = async ()=>{
const parametros = {
body:JSON.stringify({iddocumento:iddocumento.value}),
headers:{"content-type":"application/json"},
method: "post"
}
const consulta = await fetch("http://"+archivero.state.url+"/sg.php?ac=notadeventalineas",parametros)
const respuesta = await consulta.json()

let valores = {}
valores = respuesta.map((elemento: typeof respuesta)=>elemento)
lineas.value=valores

 }

const ancho = ref()

onMounted(()=>{
  

 
 ancho.value= computed(()=>{
  
  const elemento = document.getElementById("primerdiv") as HTMLElement
 return  elemento.offsetWidth+"px"
  }).value



if(iddocumento.value!==''){
montado().then(()=>{
  total.value = lineas.value[0]["totalfinal"]
  subtotal.value = lineas.value[0]["subtotalfinal"]
  descuento.value = lineas.value[0]["descuentofinal"]
  isv.value = lineas.value[0]["isv"]
  cliente.value= lineas.value[0]["cliente"]
  clientertn.value= lineas.value[0]["clientertn"]
    clienteid.value= lineas.value[0]["clienteid"]
    estadonotadeventa.value = lineas.value[0]["estado"]
    observaciones.value= lineas.value[0]["observaciones"]?lineas.value[0]["observaciones"]:''
  idrecibido.value=Number(iddocumento.value)
retenido.value = Number(iddocumento.value)>0?true:false

borrador.value = lineas.value[0]["borrador"]
  if(Number(idrecibido.value)>0){
    archivero.commit({
      type: "Documento",
      elemento: ""
    })
  }
  
})
}


})

const key = ref(1)
const matrizdedatos = reactive({
    formulario:{
    descripcion: "",
    codigo: "",
    codigodebarra: "",
    impuesto:"",
    tarifas:[] as string[],
    tipo:false,
    categoria:"",
    estocaje:"",
    cantidad:"",
    subtotal:"",
    descuento:"",
    isv:"",
    total:"",
    precio:""
}
})
const total = ref("0.00")
const subtotal = ref("0.00")
const isv = ref("0.00")
const cantidad = ref("0")
const descuento = ref("0.00")
const lineas = ref([]) as Ref
const estado = ref(false) 
const estadoclientes = ref(false) 
const estadocobros = ref(false) 
const observaciones = ref()
const cliente = ref()
const clienteid = ref(1)

const cambiarestadoclientes = () =>{
estadoclientes.value = !estadoclientes.value
if(estadoclientes.value==false){
 const criterio = document.getElementById("codigo") as HTMLInputElement
  criterio.focus()
 
}else if (estadoclientes.value==true){

nextTick(()=>{
  const busqueda = document.getElementById("clientecriterio") as HTMLInputElement
  busqueda.focus()
})
}

}

const cambiarestado = () =>{
estado.value = !estado.value
if(estado.value==false){
 const codigohtml = document.getElementById("codigo") as HTMLInputElement
  codigohtml.focus()
}else if (estado.value==true){

nextTick(()=>{
  const busquedahtml = document.getElementById("busqueda") as HTMLInputElement
  busquedahtml.focus()
})
}

}

const cambiarestadocobros = (e: number) =>{
estadocobros.value = !estadocobros.value
if(estadocobros.value==true){
  const montoacobrarelemento = document.getElementById("recibido") as HTMLInputElement
nextTick(()=>{
montoacobrarelemento.focus()
 
})
idrecibido.value = e as number

}if(estadocobros.value==true){
key.value= key.value+1
}
MetodoBusquedaCliente()

}


const imprimir = ()=>{
window.print()
window.onafterprint = function(){
  return false
}

}


const {codigo} = obtener

const Obtenerproducto = async (e:string)=>{
const respuesta = codigo(e)
matrizdedatos.formulario.tarifas.length=0
respuesta.then((respuesta:any)=>{
if(respuesta.length>0){
matrizdedatos.formulario.descripcion=respuesta[0]["descripcion"]
matrizdedatos.formulario.codigo=respuesta[0]["codigo"]
matrizdedatos.formulario.impuesto=respuesta[0]["impuesto"]
matrizdedatos.formulario.cantidad = "1"
matrizdedatos.formulario.codigodebarra=respuesta[0]["codigodebarra"]
const tarifa1 =(Number(respuesta[0]["tarifa1"])/(Number(respuesta[0]["impuesto"])+1))
const tarifa2 =(Number(respuesta[0]["tarifa2"])/(Number(respuesta[0]["impuesto"])+1))
matrizdedatos.formulario.isv = (Number(tarifa1)*Number(respuesta[0]["impuesto"])).toString()
  matrizdedatos.formulario.tarifas.push(
  tarifa1.toString()
  )
  matrizdedatos.formulario.tarifas.push(    
  tarifa2.toString()
   )

matrizdedatos.formulario.precio= tarifa1.toString()



matrizdedatos.formulario.subtotal=((Number(matrizdedatos.formulario.cantidad))*Number(matrizdedatos.formulario.tarifas[0])).toString()

matrizdedatos.formulario.total=(Number(matrizdedatos.formulario.subtotal)+Number(matrizdedatos.formulario.isv)).toString()

const cantidad = document.getElementById("cantidad") as HTMLInputElement



cantidad.focus()
if(estado.value==true){
estado.value=false
}
}
})


}

const Obtenercliente = async (e: any)=>{
cliente.value = e["cliente"]
clienteid.value = e["id"]
clientertn.value = e["rtn"]

if(estadoclientes.value==true){
  MetodoBusquedaCliente()
 
  estadoclientes.value=false
}

}












const actualizarprecio = () =>{
nextTick(()=>{
matrizdedatos.formulario.subtotal=((Number(matrizdedatos.formulario.cantidad))*(Number(matrizdedatos.formulario.precio))).toString()
matrizdedatos.formulario.isv=(Number(matrizdedatos.formulario.subtotal)*Number(matrizdedatos.formulario.impuesto)-Number(matrizdedatos.formulario.descuento)*Number(matrizdedatos.formulario.impuesto)).toString()
const total = Math.round((Number(matrizdedatos.formulario.subtotal)+Number(matrizdedatos.formulario.isv)-Number(matrizdedatos.formulario.descuento)))
matrizdedatos.formulario.total = total.toString()
const cantidad = document.getElementById("cantidad") as HTMLInputElement
cantidad.focus()
})
}

//Actualizar cantidad antes de agregar
const actualizarcantidad = () =>{ 

 
matrizdedatos.formulario.subtotal= ((Number(matrizdedatos.formulario.precio))*Number(matrizdedatos.formulario.cantidad)).toString()
matrizdedatos.formulario.isv=(((Number(matrizdedatos.formulario.subtotal)-Number(matrizdedatos.formulario.descuento))*Number(matrizdedatos.formulario.impuesto))).toString()
const Total = (Number(matrizdedatos.formulario.subtotal)+Number(matrizdedatos.formulario.isv)-Number(matrizdedatos.formulario.descuento)).toString()
matrizdedatos.formulario.total = Total
}

const actulizardescuento = () =>{   
matrizdedatos.formulario.isv=(Number(matrizdedatos.formulario.subtotal)*Number(matrizdedatos.formulario.impuesto)-Number(matrizdedatos.formulario.descuento)*Number(matrizdedatos.formulario.impuesto)).toString()
const Total =  (Number(matrizdedatos.formulario.subtotal)+Number(matrizdedatos.formulario.isv)-Number(matrizdedatos.formulario.descuento))
matrizdedatos.formulario.total = Total.toString()
}




const Limpiar = ()=>{
matrizdedatos.formulario.codigo=""
matrizdedatos.formulario.descripcion=""
matrizdedatos.formulario.precio=""
matrizdedatos.formulario.cantidad=""
matrizdedatos.formulario.subtotal=""
matrizdedatos.formulario.descuento=""
matrizdedatos.formulario.isv=""
matrizdedatos.formulario.total=""
matrizdedatos.formulario.tarifas.length=0

}





const totalizador = () =>{
Limpiar()
const arraytotal = lineas.value.map((elemento: typeof lineas.value)=>elemento["total"])
const arraysubtotal = lineas.value.map((elemento: typeof lineas.value)=>elemento["subtotal"])
const arrayisv = lineas.value.map((elemento: typeof lineas.value)=>elemento["isv"])
const arraycantidad = lineas.value.map((elemento: typeof lineas.value)=>elemento["cantidad"])
const arraydescuento = lineas.value.map((elemento: typeof lineas.value)=>elemento["descuento"])

total.value = Number(arraytotal.reduce((anterior:number,actual:number)=>{return (Number(anterior)+Number(actual))},0)).toString()
subtotal.value = (Number(arraysubtotal.reduce((anterior:number,actual:number)=>{return (Number(anterior)+Number(actual))},0))).toString()
isv.value = (Number(arrayisv.reduce((anterior:number,actual:number)=>{return (Number(anterior)+Number(actual))},0))).toString()
cantidad.value = (Number(arraycantidad.reduce((anterior:number,actual:number)=>{return (Number(anterior)+Number(actual))},0))).toString()
descuento.value = (Number(arraydescuento.reduce((anterior:number,actual:number)=>{return (Number(anterior)+Number(actual))},0))).toString()

}



 




const agregarproducto = () =>{
 

  const array = Object.entries(matrizdedatos.formulario) as any[]
const tamano = array[0][1].length


if(tamano>0){

  const resultados = lineas.value.filter((elemento:any)=>elemento.codigo==matrizdedatos.formulario.codigo)

if(resultados.length>0){

const index = lineas.value.findIndex((elemento:any)=>elemento.codigo==matrizdedatos.formulario.codigo)
const cantidad = lineas.value[index]["cantidad"] 
const subtotal = lineas.value[index]["subtotal"] 
const isv = lineas.value[index]["isv"] 
const total = lineas.value[index]["total"] 

lineas.value[index]["cantidad"] = (Number(cantidad) + Number(matrizdedatos.formulario.cantidad))
lineas.value[index]["subtotal"] = (Number(subtotal) + Number(matrizdedatos.formulario.subtotal))
lineas.value[index]["isv"] = (Number(isv) + Number(matrizdedatos.formulario.isv))
lineas.value[index]["total"] = (Number(total) + Number(matrizdedatos.formulario.total))

funcionlineas()
}else if(resultados.length==0){


if(matrizdedatos.formulario.codigodebarra!==''){

 const Total = ((Number(matrizdedatos.formulario.total)))
 
const valores = ({
codigo:matrizdedatos.formulario.codigo,
descripcion: matrizdedatos.formulario.descripcion,
precio:(Number(matrizdedatos.formulario.precio)),
cantidad:(Number(matrizdedatos.formulario.cantidad)),
subtotal:(Number(matrizdedatos.formulario.subtotal)),
descuento:(Number(matrizdedatos.formulario.descuento==''?'0.00':matrizdedatos.formulario.descuento)),
isv:(Number(matrizdedatos.formulario.isv)),
impuesto:matrizdedatos.formulario.impuesto,
total:Total,
})

const Lineas = async () =>{lineas.value.push(valores)}
Lineas().then(()=>{
funcionlineas()

})

}
}

}

}
const Commit =(e: any) =>{
archivero.commit({
type:"Documento",
elemento: e
})
}
async function Archivero(arc: typeof archivero): Promise<string>{
let id = arc.state.documento
return new Promise((bien:any,mal: any)=>{
  if(Number(id)>0){
   bien('1')
  }
  else{
    mal("0")
  }
})

}
const retenido = ref(false)

const guardar = async () =>{

const parametrotipo = ruta.params.tipo

switch(parametrotipo){

case "p":
  tipo.value="1"
  break


case "n":
  tipo.value="2"
  break


case "f":
  tipo.value="3"
  break
}

if(idrecibido.value==0 && lineas.value.length>0){

  
const parametros = {
  body:JSON.stringify({
  tipo: tipo.value,
  observaciones:observaciones.value?observaciones.value:'NINGUNA',
  cliente:cliente.value?cliente.value:'Consumidor final',
  clienteid:clienteid.value?clienteid.value:'1',
  subtotal:subtotal.value, 
  descuento: descuento.value?descuento.value:'0.00',
  idisv1: lineas.value[0].impuesto?lineas.value[0].impuesto:'0.00',
  idisv2: lineas.value[0].impuesto?lineas.value[0].impuesto:'0.00',
isv1: isv.value?isv.value:'0.00',
isv2: isv.value?isv.value:'0.00',
total:total.value?total.value:'0.00',
lineas:lineas.value,
clientertn: clientertn.value?clientertn.value:'0',
}),
  headers:{"content-type":"application/json"},
  method:"post"
}
const consulta = await fetch("http://"+archivero.state.url+"/sg.php?ac=registrarnotadeventa",parametros)
const respuesta = await consulta.json()
const estado = consulta.statusText
if(estado=='OK' && respuesta["resultado"]=='1'){
Swal.fire({
  position: 'center',
  icon: 'success',
  title: 'Registro correcto',
  showConfirmButton: false,
  timer: 1500
})

idrecibido.value = Number(respuesta["idrecibido"])
retenido.value = false
Commit(Number(respuesta["idrecibido"]))
Archivero(archivero).then((e:string)=>{

if(e=="1"){
  montado().then(()=>{
MetodoBusquedaCliente()
    total.value = lineas.value[0]["totalfinal"]
  subtotal.value = lineas.value[0]["subtotalfinal"]
  descuento.value = lineas.value[0]["descuentofinal"]
  isv.value = lineas.value[0]["isvfinal"]
  cliente.value= lineas.value[0]["cliente"]
    clienteid.value= lineas.value[0]["clienteid"]
    observaciones.value= lineas.value[0]["observaciones"]?lineas.value[0]["observaciones"]:''
})
}
})
key.value+=1
}



}
else if(idrecibido.value>0 && lineas.value.length>0 && borrador.value==0){
const parametros = {
  body:JSON.stringify({
  
  observaciones:observaciones.value?observaciones.value:'NINGUNA',
  cliente:cliente.value?cliente.value:'Consumidor final',
  clienteid:clienteid.value?clienteid.value:'1',
  id:idrecibido.value

}),
  headers:{"content-type":"application/json"},
  method:"post"
}
const consulta = await fetch("http://"+archivero.state.url+"/sg.php?ac=actualizarnotadeventa",parametros)
const respuesta = await consulta.json()
const estado = consulta.statusText
if(estado=='OK' && respuesta["resultado"]=='1'){
  Swal.fire({
  position: 'center',
  icon: 'success',
  title: 'Actualizado',
  showConfirmButton: false,
  timer: 1500
})
}

}

//Registro una nota que estaba retenida
else if(idrecibido.value>0 && lineas.value.length>0 && borrador.value==1){

  const parametros = {
  body:JSON.stringify({
  tipo: tipo.value,
  observaciones:observaciones.value?observaciones.value:'NINGUNA',
  cliente:cliente.value?cliente.value:'Consumidor final',
  clienteid:clienteid.value?clienteid.value:'1',
  subtotal:subtotal.value, 
  descuento: descuento.value?descuento.value:'0.00',
  idisv1: lineas.value[0].impuesto?lineas.value[0].impuesto:'0.00',
  idisv2: lineas.value[0].impuesto?lineas.value[0].impuesto:'0.00',
isv1: isv.value?isv.value:'0.00',
isv2: isv.value?isv.value:'0.00',
total:total.value?total.value:'0.00',
iddocumento:idrecibido.value,
lineas:lineas.value,
clientertn: clientertn.value?clientertn.value:'0',
}),
  headers:{"content-type":"application/json"},
  method:"post"
}
const consulta = await fetch("http://"+archivero.state.url+"/sg.php?ac=registrarnotadeventaretenida",parametros)
const respuesta = await consulta.json()
const estado = consulta.statusText
if(estado=='OK' && respuesta["resultado"]=='1'){
Swal.fire({
  position: 'center',
  icon: 'success',
  title: 'Retenido',
  showConfirmButton: false,
  timer: 1500
})

idrecibido.value = Number(respuesta["idrecibido"])
retenido.value = false
Commit(Number(respuesta["idrecibido"]))
Archivero(archivero).then((e:string)=>{

if(e=="1"){
  montado().then(()=>{
MetodoBusquedaCliente()
    total.value = lineas.value[0]["totalfinal"]
  subtotal.value = lineas.value[0]["subtotalfinal"]
  descuento.value = lineas.value[0]["descuentofinal"]
  isv.value = lineas.value[0]["isvfinal"]
  cliente.value= lineas.value[0]["cliente"]
    clienteid.value= lineas.value[0]["clienteid"]
    observaciones.value= lineas.value[0]["observaciones"]?lineas.value[0]["observaciones"]:''
})
}
})
key.value+=1
}


}

}

const nueva = () =>{
  
Limpiar()
lineas.value=[]
cliente.value=""
clientertn.value=""
clienteid.value=1
observaciones.value=""
total.value="0.00"
isv.value="0.00"
subtotal.value="0.00"
idrecibido.value=0
}

const borrarlinea = (codigo: string)=>{


const Lineas  = lineas.value.filter((elemento: any)=>elemento.codigo!=codigo)
lineas.value=Lineas

nextTick(()=>{
  funcionlineas()
})
}


const cambiarpreciolinea = (e: any,i:string) =>{  
const codigo = i
const precio = e.target.innerText?e.target.innerText:0.00 
const index = lineas.value.findIndex((elemento: any)=>elemento.codigo==codigo)
const subtotalviejo = Number(lineas.value[index]["subtotal"])!==0?lineas.value[index]["subtotal"]:1
const precioviejo = Number(lineas.value[index]["precio"])!==0?lineas.value[index]["precio"]:1
const funcionactualizarlinea = async() =>{
if(!isNaN(precio)){
 if(Number(precio)!==0){
const isv = (lineas.value[index]["isv"])
const impuesto = Number(isv)/(Number(subtotalviejo))
const cantidad = lineas.value[index]["cantidad"]
const descuento = lineas.value[index]["descuento"]
let subtotal = lineas.value[index]["subtotal"]
lineas.value[index]["subtotal"]= (Number(cantidad)*Number(Number(precio)!==0?precio:0.00)-Number(descuento))

nextTick(()=>{
subtotal = lineas.value[index]["subtotal"]
lineas.value[index]["isv"]= Number(impuesto)*Number(subtotal)
lineas.value[index]["total"]=Number(subtotal)+Number(subtotal)*Number(impuesto)

})

}else{
  
lineas.value[index]["precio"]=(Number(precioviejo))

}
}}

funcionactualizarlinea().then(()=>{
nextTick(()=>{
  totalizador()
})
})
}



const cambiartotallinea = (e: any,i:string) =>{  

const codigo = i
const index = lineas.value.findIndex((elemento: any)=>elemento.codigo==codigo)

const total = lineas.value[index]["total"]

const subtotalviejo = Number(lineas.value[index]["subtotal"])!==0?lineas.value[index]["subtotal"]:1
const funcionactualizarlinea = async() =>{
if(!isNaN(total)){
 if(Number(total)!==0){
const isv = (lineas.value[index]["isv"])
const impuesto = Number(isv)/(Number(subtotalviejo))
const cantidad = lineas.value[index]["cantidad"]
const descuento = lineas.value[index]["descuento"]
const totalnuevo = Number(total)
const precionuevo = (Number(totalnuevo)/Number(cantidad))/Number(1+impuesto)
const isvnuevo = ((precionuevo*cantidad)-(descuento))*impuesto
nextTick(()=>{
lineas.value[index]["isv"]= isvnuevo
lineas.value[index]["precio"]= precionuevo
lineas.value[index]["subtotal"]= precionuevo*cantidad

})

}
}}

funcionactualizarlinea().then(()=>{
nextTick(()=>{
  totalizador()
})
})
}



const cambiarestadoformulariocliente = () =>{
  estadoformulariocliente.value = !estadoformulariocliente.value 
cambiarestadoclientes()
}

context.expose({
cambiarestadoformulariocliente

})
onUpdated(()=>{
   document.addEventListener('keyup',(evento: KeyboardEvent)=>{
evento.preventDefault

if(evento.key=="Escape"){
  estadoclientes.value= false
  estadocobros.value= false
  estadoformulariocliente.value=false
  estado.value=false

}
    
   })
})
//Metodos provistos
provide('cambiarestadocobros',cambiarestadocobros)
provide('Obtenerproducto',Obtenerproducto)
provide('Obtenercliente',Obtenercliente)

//Enviar a reporte de notas de venta
const reporte = () =>{

    rutas.push({
        name: 'Ventas'       
      }) 

}
const retener = async () =>{

  const parametrotipo = ruta.params.tipo

switch(parametrotipo){

case "p":
  tipo.value="1"
  break


case "n":
  tipo.value="2"
  break


case "f":
  tipo.value="3"
  break
}

if(lineas.value.length>0){
const parametros = {
  body:JSON.stringify({
  tipo: tipo.value,
  observaciones:observaciones.value?observaciones.value:'NINGUNA',
  cliente:cliente.value?cliente.value:'Consumidor final',
  clienteid:clienteid.value?clienteid.value:'1',
  subtotal:subtotal.value, 
  descuento: descuento.value?descuento.value:'0.00',
  idisv1: lineas.value[0].impuesto?lineas.value[0].impuesto:'0.00',
  idisv2: lineas.value[0].impuesto?lineas.value[0].impuesto:'0.00',
isv1: isv.value?isv.value:'0.00',
isv2: isv.value?isv.value:'0.00',
total:total.value?total.value:'0.00',
iddocumento: idrecibido.value?idrecibido.value:'0',
lineas:lineas.value,
clientertn: clientertn.value?clientertn.value:'0',
}),
  headers:{"content-type":"application/json"},
  method:"post"
}
const consulta = await fetch("http://"+archivero.state.url+"/sg.php?ac=registrarnotadeventaret",parametros)

const respuesta = await consulta.json()
const estado = consulta.statusText
if(estado=='OK' && respuesta["resultado"]=='1'){
Swal.fire({
  position: 'center',
  icon: 'success',
  title: 'Retenido',
  showConfirmButton: false,
  timer: 1500
})

idrecibido.value = Number(respuesta["idrecibido"])
retenido.value = true
borrador.value = 1
Commit(Number(respuesta["idrecibido"]))



Archivero(archivero).then((e:string)=>{

if(e=="1"){
  montado().then(()=>{
MetodoBusquedaCliente()
    total.value = lineas.value[0]["totalfinal"]
  subtotal.value = lineas.value[0]["subtotalfinal"]
  descuento.value = lineas.value[0]["descuentofinal"]
  isv.value = lineas.value[0]["isvfinal"]
  cliente.value= lineas.value[0]["cliente"]
    clienteid.value= lineas.value[0]["clienteid"]
    observaciones.value= lineas.value[0]["observaciones"]?lineas.value[0]["observaciones"]:''
})
}
})









key.value+=1
}}



}

const estadocondiciones = ref(false)

const condiciones = () =>{

  estadocondiciones.value = !estadocondiciones.value
  if(estadocondiciones.value){
const html = document.getElementById('fechadecredito') as HTMLInputElement
nextTick(()=>{
  
html.focus()
 
})
}
}

const guardarcredito = async () =>{

 const parametros = {
  method:"post",
  headers: {"content-type":"application/json"},
  body: JSON.stringify({iddocumento:idrecibido.value,recargo: recargo.value, fecha: fechadecredito.value})
}


const consulta = await fetch("http://api.utp.hn/sg.php/sg.php?ac=registrarcredito",parametros)
const respuesta = await consulta.json()

if(respuesta["resultado"]==1){

bloquearcredito()  
alert("Procesado")


}





}

return{
  estadonotadeventa,
  fechadecredito,
  recargo,
  guardarcredito,
  estadocondiciones,
condiciones,
  borrador,
retenido,
retener,
MetodoBusquedaCliente,
saldo,
tipotitulo,  
clientertn,
ancho,
estadoformulariocliente,
cambiarestadoformulariocliente,
hoy,
imprimir,
key,
reporte,
estadocobros,
cambiarestadocobros,
cambiarpreciolinea,
cambiartotallinea,
borrarlinea,
cliente,
actualizarprecio,
interruptor,
idrecibido,
guardar,
descuento,
nueva,
total,
subtotal,
isv,
cantidad,
observaciones,
actualizarcantidad,
actulizardescuento,
Obtenerproducto,
...toRefs(matrizdedatos),
estado,
cambiarestado,
cambiarestadoclientes,
estadoclientes,
agregarproducto,
lineas
}
}

})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "7900b9cd": (_ctx.ancho)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__