
import {  defineComponent,nextTick, onBeforeMount, onMounted,  watch } from "@vue/runtime-core";
import {  computed, ref } from "vue";
import Cobros from "@/components/metodos/formulariocobros.vue";
import Anular from "@/components/metodos/formularioanularnota.vue";
import archivero from "@/store"
import { useRoute, useRouter } from "vue-router";
import tiempo from "@/components/metodos/tiempo.vue"


export default defineComponent({

components: {
  Cobros, Anular
  },

setup(){

const rutas = useRouter()
const ruta = useRoute()
const idrecibido = ref(0)
const estadocobros = ref(false)
const estadoanular = ref(false)
const filtradoporestado = ref(0)
const { hoy } = tiempo

const Cobros = ref(0)
const tipo = ref()
const tipotitulo = ref("")
const notaspaginadas = ref()
const busqueda = ref()
const ultimocorte = ref('')

const ultimoindex= ref(0)
const totalfinal = ref("0.00")
const productos = ref()
const productosrespaldo = ref()
const fecha1 = ref()
const fecha2 = ref()
const spin = ref(false)
const estado = ref(false)
const id = ref()
const total = ref('0.00')
const estadodetalledelineas = ref(false)
const productoslineas = ref()
const ClienteBusqueda = ref('')

const titulo = ()=>{
const parametrotipo = ruta.params.tipo
total.value="0.00"

switch(parametrotipo?.toString()){

case "p":
  tipotitulo.value="PRESUPUESTOS"
  break;


case "n":
  tipotitulo.value="NOTAS"
  break;


case "f":
  tipotitulo.value="FACTURAS"
  break;
}
}

onBeforeMount(()=>{

if(archivero.state.fecha1==''){
  archivero.commit({
  type: "Parametros",
  fecha1: hoy.value, 
  fecha2: hoy.value,
})


fecha1.value=hoy.value
fecha2.value=hoy.value
}else{
fecha1.value=archivero.state.fecha1
fecha2.value=archivero.state.fecha2

}


if(archivero.state.cliente!=""){
ClienteBusqueda.value=archivero.state.cliente
}

})


//metodo de filtrado 
const MetodoPaginado = () => {

notaspaginadas.value=  productos.value.filter((elemento: typeof productos.value)=>productos.value.indexOf(elemento)<10)
if(notaspaginadas.value.length<10){
ultimoindex.value=0
}

}
//Obtener notas
const obtenernotas = async () =>{
  const parametrotipo = ruta.params.tipo
total.value="0.00"
switch(parametrotipo){

case "p":
  tipo.value="1"
  break


case "n":
  tipo.value="2"
  break


case "f":
  tipo.value="3"
  break
}
archivero.commit({
  type:"Parametros",
  fecha1: fecha1.value,
  fecha2: fecha2.value,  
})
const parametros = {
body:JSON.stringify({fecha1:fecha1.value,fecha2:fecha2.value, tipo:tipo.value}),
headers:{"content-type":"application/json"},
method: "post"
}

const consulta = await fetch("http://"+archivero.state.url+"/sg.php?ac=notasdeventa",parametros)
const respuesta = await consulta.json()
const estado = consulta.statusText.toString()

if(estado=='OK'){
productos.value = respuesta
productosrespaldo.value = productos.value

MetodoPaginado()
//Retorno la promesa de resultado
if(respuesta.length>0){
  return Promise.resolve("1")
}else{

  return Promise.resolve("0")
}
}

}





//Funcion para actualizar el total despues de filtrar el estado
const actualizartotal = () =>{

  const array = productos.value.map((elemento:any)=>{
    return elemento["total"]
  })

if(filtradoporestado.value>0){

total.value= (Number(array.reduce((anterior:unknown,actual:unknown)=>{
  return Number(anterior)+Number(actual)
},0))-Number(Cobros.value)).toFixed(2)
}else{
 total.value= (Number(array.reduce((anterior:unknown,actual:unknown)=>{
  return Number(anterior)+Number(actual)
},0))).toFixed(2) 
}

}

const MetodoCobros = async (): Promise<number | undefined> =>{
const parametros = {
  headers:{"content-type":"application/json"},
  body:JSON.stringify({fecha1: fecha1.value, fecha2: fecha2.value}),
  method:"post"}

const consulta = await fetch("http://"+archivero.state.url+"/sg.php?ac=cobrospordiayventa",parametros)
const respuesta = await consulta.json()

Cobros.value = (respuesta.filter((elemento: any)=>{
return elemento.cliente.match(ClienteBusqueda.value.toUpperCase()) && elemento.estado<3
})).map((elemento:any)=>{
 return elemento["monto"]
}).reduce((anterior:any,actual:any)=>(Number(anterior)+Number(actual)).toString(),0)

if(consulta.statusText=="OK"){
  return Promise.resolve(1)
}

}
 //Funcion para filtrar por estado
const cambiarfiltradoporestado = () =>{
MetodoCobros()
if(filtradoporestado.value!==0){
ultimoindex.value=0
if(filtradoporestado.value<3){
productos.value = productosrespaldo.value.filter((elemento: typeof productosrespaldo.value)=>elemento.estado==1 && elemento.cliente.match(ClienteBusqueda.value.toUpperCase()) || elemento.estado==2 && elemento.cliente.match(ClienteBusqueda.value.toUpperCase()))
actualizartotal()
MetodoPaginado()
}

if(filtradoporestado.value==3){
productos.value = productosrespaldo.value.filter((elemento: typeof productosrespaldo.value)=>elemento.estado==3 && elemento.cliente.match(ClienteBusqueda.value.toUpperCase()))
actualizartotal()
MetodoPaginado()
}

}





else{
productos.value = productosrespaldo.value.filter((elemento: typeof productosrespaldo.value)=>elemento.cliente.match(ClienteBusqueda.value.toUpperCase()))
actualizartotal()
MetodoPaginado()  
}
}

//Funcion para obtener productos y pasar metodos con filtros
const obtenercompleta = async():Promise<string> =>{
 

const OB = obtenernotas().then((e: any)=>{

if(e=="1"){
notaspaginadas.value=  productos.value.filter((elemento: typeof productos.value)=>productos.value.indexOf(elemento)<10)
const array = productos.value.map((e: typeof productos.value)=>e.total)
total.value= Number(array.reduce((anterior:unknown,actual:unknown)=>{
  return Number(anterior)+Number(actual)
},0)).toFixed(2).toString()
  cambiarfiltradoporestado() 
  
}

return e

})
//Retorno la promesa de resultado
return Promise.resolve(OB)   


 } 

const funcionprimaria = ()=>{
  
  const index = archivero.state.index

//Esperamos la promesa de resultado
obtenercompleta().then((e: string)=>{

if(Number(e)>0){
 
//Confirmamos que se lleno el index en el archivero
 if(archivero.state.index!=999999){

  cambiarpaginacion(index)
 
  }
}

})

}
const tiporeactivo = computed(()=>ruta.params.tipo)



onBeforeMount(async ()=>{
  
  const parametros = {
body:JSON.stringify({}),
headers:{"content-type":"application/json"},
method: "post"
}
const consulta = await fetch("http://"+archivero.state.url+"/sg.php?ac=ultimocorte",parametros)
const respuesta = await consulta.json()
ultimocorte.value = respuesta[0]['fechaconhora']
})

onMounted( async()=>{

  funcionprimaria()
  watch(tiporeactivo,()=>{
   
funcionprimaria()
titulo()

},{immediate:true})
})









const cambiarpaginacion = (index: number) =>{
archivero.commit({
  type:"Index", 
  index: index,
})

ultimoindex.value = index  
notaspaginadas.value=  productos.value.filter((elemento: any)=>
productos.value.indexOf(elemento)>=10*(ultimoindex.value) && productos.value.indexOf(elemento)<10*(ultimoindex.value+1))

}
const cambiarestadocobros = (e: string) =>{
estadocobros.value = !estadocobros.value
if(estadocobros.value==true){
const montoacobrarelemento = document.getElementById("recibido") as HTMLInputElement
nextTick(()=>{
montoacobrarelemento.focus()
 
})


idrecibido.value = Number(e) as number
}
if(estadocobros.value==false){


cambiarspin()
obtenercompleta().then((e:any)=>{

    if(e=="1" && ClienteBusqueda.value!=""){
   
 notaspaginadas.value=  productos.value.filter((elemento: any)=>
  productos.value.indexOf(elemento)>=10*(ultimoindex.value) && productos.value.indexOf(elemento)<10*(ultimoindex.value+1))
cambiarspin()
  }else{
     notaspaginadas.value=  productos.value.filter((elemento: any)=>
  productos.value.indexOf(elemento)>=10*(ultimoindex.value) && 
  productos.value.indexOf(elemento)<10*(ultimoindex.value+1))
cambiarspin()
  }

})
}
}

const cambiarestadoanular = (e: number) =>{

estadoanular.value = !estadoanular.value
if(estadoanular.value==true){
const montoacobrarelemento = document.getElementById("comentarios") as HTMLInputElement
nextTick(()=>{
montoacobrarelemento.focus()
 
})

idrecibido.value = e as number
}
if(estadoanular.value==false){

cambiarspin()
obtenercompleta().then((e:any)=>{

    if(e=="1" && ClienteBusqueda.value!=""){
   
 notaspaginadas.value=  productos.value.filter((elemento: any)=>
  productos.value.indexOf(elemento)>=10*(ultimoindex.value) && productos.value.indexOf(elemento)<10*(ultimoindex.value+1))
cambiarspin()
  }else{
     notaspaginadas.value=  productos.value.filter((elemento: any)=>
  productos.value.indexOf(elemento)>=10*(ultimoindex.value) && 
  productos.value.indexOf(elemento)<10*(ultimoindex.value+1))
cambiarspin()
  }

})
}
}



const cambiarspin = () =>{
spin.value = !spin.value

}

const cambiarestadodetalledelineas = () =>{
estadodetalledelineas.value = !estadodetalledelineas.value

}

const cambiarestado = (e: string) =>{
estado.value = !estado.value
id.value = e
if(id.value!='' && estado.value==true){
cambiarspin()
}


}


const mostrar = async (e: any) =>{
const id = e
productoslineas.value=[]
totalfinal.value="0.00"
async function funcion(){
const parametros = {
body:JSON.stringify({iddocumento:id}),
headers:{"content-type":"application/json"},
method: "post"
}
const consulta = await fetch("http://"+archivero.state.url+"/sg.php?ac=notadeventalineas",parametros)
const respuesta = await consulta.json()
productoslineas.value = respuesta 
totalfinal.value= respuesta[0]["totalfinal"]
 }
funcion().then(function(){
  estadodetalledelineas.value=true
})

}

//Ver detalle de nota de venta
const nuevaventa = () =>{

   archivero.commit({
     type:"Documento",
     elemento: ""
   })

    rutas.push({
         name: 'NuevaVenta',params:{tipo:ruta.params.tipo}     
      }) 


}


const abrirdocumento = async(e: string) =>{
async function primera(){
archivero.commit({
  type: 'Documento',
  elemento: e
})
}
  
primera().then(()=>{

 rutas.push({
        name: 'NuevaVenta',params:{tipo:ruta.params.tipo}
      }) 

})



}




const MetodoBusquedaCliente = () =>{

MetodoCobros().then((e: any)=>{
if(e==1){

archivero.commit({
type: "Cliente",
cliente: ClienteBusqueda.value
})

if(filtradoporestado.value<1 ){
const resultado = productosrespaldo.value.filter((e: typeof productosrespaldo.value)=>
e.cliente.match(ClienteBusqueda.value.toUpperCase()))
productos.value = resultado
MetodoPaginado()
actualizartotal()

}
if(filtradoporestado.value<3 && filtradoporestado.value>0){
 

productos.value=productosrespaldo.value.filter((e: typeof productosrespaldo.value)=>e.estado<3 
&& e.cliente.match(ClienteBusqueda.value.toUpperCase()))

actualizartotal()
MetodoPaginado()
}

if(filtradoporestado.value==3){
productos.value=productosrespaldo.value.filter((e: typeof productosrespaldo.value)=>e.estado==3 
&& e.cliente.match(ClienteBusqueda.value.toUpperCase()))
actualizartotal()
MetodoPaginado()
}

}
})


}

const alerta = (e:any) =>{
alert(e)  
}



return{
alerta,
ultimocorte,
ultimoindex,
tipotitulo,
ClienteBusqueda,
MetodoBusquedaCliente,
abrirdocumento,
nuevaventa,
filtradoporestado,
cambiarfiltradoporestado,
cambiarestadocobros,
estadocobros,
idrecibido,
totalfinal,
obtenercompleta,
mostrar,
productoslineas,
cambiarestadodetalledelineas,
estadodetalledelineas,
estado,
total,
fecha1,fecha2,
cambiarpaginacion,
notaspaginadas,
spin,
cambiarspin,
productos,
cambiarestado,
cambiarestadoanular,
obtenernotas,
id,
estadoanular,
busqueda,
hoy,
}
}

})
