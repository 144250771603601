
import { defineComponent,  onMounted } from "@vue/runtime-core";
import { ref } from "vue";
import Swal from "sweetalert2"

import Formularioproducto from "./metodos/formularioproducto.vue";
import archivero from "@/store"

export default defineComponent({

name:"Productos",
components: {
  Formularioproducto},

setup(){
const ultimoindex = ref(0)
const productos = ref()
const productos2 = ref()
const spin = ref(false)
const estado = ref(false)
const id = ref()

const Categorias = ref()
const categoria = ref()

const obtenerproductos = async (): Promise<any> =>{
const parametros = {
body:JSON.stringify({criterio:''}),
headers:{"content-type":"application/json"},
method: "post"
}
const consulta = await fetch("http://"+archivero.state.url+"/sg.php?ac=productos",parametros)
const respuesta = await consulta.json() 
const Respuesta = Object.values(respuesta)
const estado = consulta.statusText.toString()
if(estado=='OK'){
return Respuesta
}


}


const productospaginados = ref()

const ObtenerProductos = ()=>{

productospaginados.value=[]

obtenerproductos().then((e:any)=>{
productos.value = e
productos2.value = e
 
productospaginados.value=  productos.value.filter((elemento: any)=>productos.value.indexOf(elemento)<10 )

buscar()


if(archivero.state.indexproductos!=999999){
cambiarpaginacion(archivero.state.indexproductos)
}


const uniqueIds = new Set();

Categorias.value = e.filter((element:any) => {
  const isDuplicate = uniqueIds.has(element.categoria);

  uniqueIds.add(element.categoria);

  if (!isDuplicate) {
    return true;
  }

  return false;
})
})

}

const cambiarcategoria = () =>{

  productos.value = productos2.value.filter((elemento: any)=>(elemento.descripcion?.toUpperCase()).match(busqueda.value?.toUpperCase())  || (elemento.codigo.toUpperCase()).match(busqueda.value.toUpperCase())).filter((elemento: any)=>(elemento.categoria?.toUpperCase()).match(categoria.value))
productospaginados.value=  productos.value.filter((elemento: any)=>productos.value.indexOf(elemento)<10)

}


onMounted( async()=>{

ObtenerProductos()




})


const cambiarpaginacion = (index: number) =>{
  ultimoindex.value = index
  productospaginados.value=  productos.value.filter((elemento: any)=>
  productos.value.indexOf(elemento)>=10*(index) && productos.value.indexOf(elemento)<10*(index+1))
  archivero.commit({
    type: "IndexProductos",
    index: index  
  })
  

}









const cambiarspin = () =>{
spin.value = !spin.value

}



const cambiarestado = (e: string) =>{
estado.value = !estado.value
id.value = e
if(id.value!='' && estado.value==true){
cambiarspin()
}

console.log(ultimoindex.value)

}

const busqueda = ref()
const buscar = () => {
productos.value = productos2.value.filter((elemento: any)=>(  elemento.codigodebarra?.toUpperCase()).match(busqueda.value?.toUpperCase())  || (  elemento.descripcion?.toUpperCase()).match(busqueda.value?.toUpperCase())  || (elemento.codigo.toUpperCase()).match(busqueda.value.toUpperCase())).filter((elemento: any)=>(elemento.categoria?.toUpperCase()).match(categoria.value))
productospaginados.value=  productos.value.filter((elemento: any)=>productos.value.indexOf(elemento)<10)

}


const eliminar = async (e: number) =>{
const parametros = {
method: "post",
headers: {"content-type":"application/json"},
body: JSON.stringify({id:e})
}

const consulta = await fetch("http://"+archivero.state.url+"/sg.php?ac=productoseliminar",parametros)
const respuesta = await consulta.json()
const estado = consulta.statusText
if(estado=="OK" && respuesta["resultado"]=="1"){
Swal.fire({
  position: 'center',
  icon: 'success',
  title: 'Producto eliminado',
  showConfirmButton: false,
  timer: 1500
})
ObtenerProductos()
}


}

return{
cambiarcategoria,
Categorias,
categoria,
eliminar,
ultimoindex,
cambiarpaginacion,
productospaginados,
spin,
cambiarspin,
productos,
estado,
cambiarestado,
ObtenerProductos,
id,
buscar,
busqueda,
}
}

})
