
import { defineComponent, onMounted, } from "@vue/runtime-core";
import { ref } from "vue";
import  archivero  from "@/store";
import { useRouter } from "vue-router";


export default defineComponent({

name:"Productos",

setup(){
const rutas = useRouter()
const pin = ref()
  const ingresar = async()=>{
    const primera = async()=>{
if(pin.value==19912)
{
sessionStorage.setItem("sesion","1")
 }}

primera().then(async ()=>{
  if(sessionStorage.getItem("sesion")=="1"){



rutas.push("/")


  }
  
})

 
 }

 onMounted(()=>{
   const elemento = document.getElementById("sesion") as HTMLInputElement
   elemento.focus()
 })
return {
  ingresar, pin
}

}



})
