
import { defineComponent, onMounted } from "@vue/runtime-core";
import { ref } from "vue";
import Formularioproveedor from "./metodos/formularioproveedor.vue";
import archivero from "@/store"

export default defineComponent({

name:"Productos",
components: {
  Formularioproveedor},

setup(){

const productos = ref()
const productos2 = ref()
const spin = ref(false)
const estado = ref(false)
const id = ref()



const obtenerproductos = async () =>{


const parametros = {
body:JSON.stringify({criterio:''}),
headers:{"content-type":"application/json"},
method: "post"
}


const consulta = await fetch("http://"+archivero.state.url+"/sg.php?ac=proveedores",parametros)
const respuesta = await consulta.json() 
const Respuesta = Object.values(respuesta)

const estado = consulta.statusText.toString()
if(estado=='OK'){
productos.value = Respuesta
productos2.value = productos.value

}
}


const productospaginados = ref()

const ObtenerProductos = ()=>{
productospaginados.value=[]
obtenerproductos().then(()=>{
productospaginados.value=  productos.value.filter((elemento: any)=>productos.value.indexOf(elemento)<10)
})
}

onMounted( async()=>{

ObtenerProductos()


})


const cambiarpaginacion = (index: number) =>{
  productospaginados.value=  productos.value.filter((elemento: any)=>
  productos.value.indexOf(elemento)>=10*(index) && productos.value.indexOf(elemento)<10*(index+1))

}









const cambiarspin = () =>{
spin.value = !spin.value
}

const cambiarestado = (e: string) =>{
estado.value = !estado.value
id.value = e
if(id.value!='' && estado.value==true){ 
cambiarspin()
}
}

const busqueda = ref()
const buscar = () => {
productos.value = productos2.value.filter((elemento: any)=>(elemento.cliente.toUpperCase()).match(busqueda.value.toUpperCase()) )
productospaginados.value=  productos.value.filter((elemento: any)=>productos.value.indexOf(elemento)<10)




}



return{
cambiarpaginacion,
productospaginados,
spin,
cambiarspin,
productos,
estado,
cambiarestado,
ObtenerProductos,
id,
buscar,
busqueda,
}
}

})
