<template>
<div>
<slot></slot>
</div>
</template>

<script>
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({

setup(){

return{

}

}


})
</script>

<style>

</style>