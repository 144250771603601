import { vModelText as _vModelText, withKeys as _withKeys, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-64d61724"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "p-0 row justify-content-start mt-2" }
const _hoisted_3 = { class: "col-md-4 m-0 p-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _withDirectives(_createElementVNode("input", {
          id: "sesion",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.pin) = $event)),
          placeholder: "INGRESE EL PIN",
          type: "password",
          onKeyup: _cache[1] || (_cache[1] = _withKeys(
//@ts-ignore
(...args) => (_ctx.ingresar && _ctx.ingresar(...args)), ["enter"])),
          class: "form-control"
        }, null, 544), [
          [_vModelText, _ctx.pin]
        ])
      ])
    ])
  ]))
}