import { createStore } from 'vuex'


const url = ("api.utp.hn/sg.php")

export type archivero ={
  url:string;
  documento:string,
  fecha1:string,
  fecha2: string,
  cliente:string,
  index:number,
  empresa: string[],
  indexproductos:number,

}


export default createStore<archivero>({
  state: {
    url: url,
    documento:"",
    fecha1:"",
    fecha2:"",
    cliente:"",
    index:999999,
    empresa: [],
    indexproductos:999999,
   
  },
  mutations: {
    Documento(state,elemento){
    state.documento = elemento.elemento
    },
    Empresa(state, datos){
    state.empresa = datos.datos
    },
    Parametros(state,elemento){
      state.fecha1= elemento.fecha1
      state.fecha2= elemento.fecha2
     
    },
    Index(state,elemento){
      state.index= elemento.index
    },
    Cliente(state,elemento){
      state.cliente= elemento.cliente
    },
    IndexProductos(state,elemento){
      state.indexproductos= elemento.index
   
    }




  },
  actions: {
  },
  
  modules: {
  }
})
