
import { computed, defineComponent, onUpdated, watchEffect} from "@vue/runtime-core";
import { ref } from "vue";
import archivero from '@/store'

export default defineComponent({
name: "Buscar Producto",
props:{
estado:Boolean,
idrecibido: Number,
},
emits:{
cambiarestado : Function,

},
setup(props,context){
const iddocumento = computed(()=>{
return props.idrecibido
})


const idcliente = ref()
const totaloriginal= ref("0.00")
const cobros = ref()
const totalcobros =  ref("0.00")
const totalpendiente=  ref("0.00")
const totalpendientefijo=  ref("0.00")
const venta = ref()
const montoacobrar =  ref("")
const obtenercobros = async (e: number) =>{
const parametros = {
    body:JSON.stringify({iddocumento:e}),
    method:"post",
    headers:{"Content-type":"application/json"}
}
const consulta = await fetch("http://api.utp.hn/sg.php/sg.php?ac=cobros",parametros)
const respuesta = await consulta.json()
cobros.value = respuesta

}



const recibido = ref("")
const cambio = ref("")


const obtenerventas = async (e: number) =>{
const parametros = {
    body:JSON.stringify({iddocumento:e}),
    method:"post",
    headers:{"Content-type":"application/json"}
}
const consulta = await fetch("http://api.utp.hn/sg.php/sg.php?ac=notadeventalineas",parametros)
const respuesta = await consulta.json()
venta.value = respuesta

}
const estado = computed(()=>{
    return props.estado
})

const formasdepago = ref()
const obtenerformasdepago = async () =>{

const parametros = {
    method:"post",
    headers:{"content-type":"application/json"}
}
const consulta = await fetch("http://api.utp.hn/sg.php/sg.php?ac=formasdepago",parametros)
const respuesta = await consulta.json()
formasdepago.value = respuesta


}
const funcionobtener = () =>{
obtenerformasdepago()

    obtenercobros(iddocumento.value as number).then(()=>{
obtenerventas(iddocumento.value as number).then(()=>{

const arraycobros = cobros.value.map((elemento: any)=>elemento["monto"])

const arrayventa = venta.value.map((elemento: any)=>elemento["total"])

totaloriginal.value = Number(arrayventa.reduce((anterior:number,actual:number)=>{return (Number(anterior)+Number(actual))},0)).toFixed(2)

totalcobros.value = Number(arraycobros.reduce((anterior:number,actual:number)=>{return (Number(anterior)+Number(actual))},0)).toFixed(2)


totalpendiente.value = (Number(totaloriginal.value)-Number(totalcobros.value)).toFixed(2)

totalpendientefijo.value=totalpendiente.value
idcliente.value=venta.value.map((elemento: any)=>elemento["clienteid"])[0]

const montoacobrarelemento = document.getElementById("recibido") as HTMLInputElement
if(totalpendientefijo.value=="0.00"){
    montoacobrarelemento.readOnly=true
}else{
    montoacobrarelemento.readOnly=false
}


})
})
}

const cancelarcobro = async (id: string, monto:string) =>{
    const parametros = {
    body:JSON.stringify({iddocumento: props.idrecibido, idcobro:id, monto:monto}),
    method:"post",
    headers:{"content-type":"application/json"}   
     
}

const consulta = await fetch("http://"+archivero.state.url+"/sg.php?ac=cancelarcobro",parametros)
const estado = consulta.statusText.toString()

if(estado=='OK'){
funcionobtener()
const elemento = document.getElementById("recibido") as HTMLElement
elemento.focus()

}
}
watchEffect(()=>{

if(estado.value==true){
   
funcionobtener()
 }
  
})

onUpdated(()=>{
if(Number(totalpendientefijo.value)>=Number(montoacobrar.value)){

totalpendiente.value = (Number((Number(totaloriginal.value)-Number(totalcobros.value)).toFixed(2))-Number(montoacobrar.value)).toFixed(2)

}else{
    montoacobrar.value=""
}
})







const cambiarestado = ()=>{
totalpendiente.value="0.00"
totaloriginal.value="0.00"
totalcobros.value="0.00"
montoacobrar.value=""    
context.emit('cambiarestado')
cobros.value=[]
}

const cobrar = async () =>{
const html = document.getElementById("formadepagomodelo") as HTMLSelectElement
const option = html.options[html.selectedIndex]

const parametros = {
    body:JSON.stringify({        
      iddocumento:iddocumento.value,
      idcliente:idcliente.value,
      montoacobrar:montoacobrar.value,
      formadepago:option.text,
      formadepagoid:option.value    
      }),
    method:"post",
    headers:{"Content-type":"application/json"}
}
const consulta = await fetch("http://api.utp.hn/sg.php/sg.php?ac=registrarcobro",parametros)
const respuesta = await consulta.json()
const resultado = respuesta["resultado"]
if(resultado=="1"){

funcionobtener()

recibido.value=""
cambio.value=""
montoacobrar.value=""

}


}

const cambiarrecibido = () =>{
 
if(Number(recibido.value)==Number(totalpendientefijo.value)){
    montoacobrar.value= (Number(recibido.value)).toFixed(2)
    cambio.value= "0.00"

}
if(Number(recibido.value)<Number(totalpendientefijo.value)){
    montoacobrar.value= (Number(recibido.value)).toFixed(2)
    cambio.value= (-Number(recibido.value)+Number(montoacobrar.value)).toFixed(2)
 
}

if(Number(recibido.value)>Number(totalpendientefijo.value)){
  
    montoacobrar.value= (Number(totalpendientefijo.value)).toFixed(2)
    cambio.value= (Number(recibido.value)-Number(montoacobrar.value)).toFixed(2)

}

}

return{

cambio,
formasdepago,
recibido,
cambiarrecibido,
cancelarcobro,
cobrar,
montoacobrar,
totaloriginal,
totalcobros,
totalpendiente,
cambiarestado,
cobros,
venta
}
}

})
