
import { defineComponent, getCurrentInstance, inject} from "@vue/runtime-core";
import { ref } from "vue";
import obtener from "@/components/metodos/obtener.vue"
export default defineComponent({
name: "Buscar cliente",
props:{
estado:Boolean
},
emits:{
cambiarestado : Function,

},
setup(){
const Obtenercliente = inject('Obtenercliente')

const clientes = ref([])
const busqueda = ref()
const {clientenombre} = obtener
const obtenercliente = () =>{
const respuesta = clientenombre(busqueda.value) 
respuesta.then((respuesta: typeof clientes.value)=>{
clientes.value = respuesta

})

}

const instancia = getCurrentInstance()
const formulariocliente = () =>{
instancia?.parent?.exposed!["cambiarestadoformulariocliente"]()

}

return{


obtenercliente,
busqueda,
clientes,
Obtenercliente,
formulariocliente,
    }
}

})
