
import {defineComponent, nextTick, watchEffect} from "@vue/runtime-core";
import {reactive, ref, toRefs } from "vue";
import archivero from "@/store"
import Swal from "sweetalert2";
export default defineComponent({
props:{
    estado:Boolean,
      spin: Boolean,
      totalcobrado: String,
      totalventa: String,
      totalcobradohoy: String,
      totalanteriores: String,
      hoy: String,
      anteriores: String,
      corteacumulado: String,
      ventaacumulada: String,
      hoyacumulado: String,
      anterioresacumulado: String,
},
emits:{
    cambiarestado:Function,
    obtenerproductos:Function,
    cambiarspin:Function,
},
directives: {
  mayuscula: {
     beforeUpdate: function(el: any){
        nextTick(()=>{
        const valor =el.value.toUpperCase()
        el.value= valor
        
        })
        
    }
  },

},
setup(props, context){


const matrizdedatos = reactive({
    formulario:{
    observaciones: "",
    declarado: "",
}
})


watchEffect(() =>{
  
      

if(props.estado==true){
   nextTick(()=>{
   const declarado = document.getElementById("declarado") as HTMLInputElement
     declarado.focus()
   })
 
}     

})











const cambiarestado = ()=>{

  const vaciar = async() =>{
    matrizdedatos.formulario.declarado= ""
     matrizdedatos.formulario.observaciones= ""
    
  }
  vaciar().then(()=>{
  context.emit('cambiarestado')
  
  })
    



}











const procesar = async () =>{
 
if( props.totalcobrado!= "" &&
    matrizdedatos.formulario.declarado!=""  ){
const parametros = {
body: JSON.stringify({observaciones:matrizdedatos.formulario.observaciones, 
declarado: matrizdedatos.formulario.declarado, 
corte:Number(props.totalcobrado)-Number(props.corteacumulado),
 venta:Math.abs(Number(props.totalventa)-Number(props.ventaacumulada)),
 hoy:Math.abs(Number(props.hoyacumulado)==0?Number(props.totalcobradohoy):Number(props.hoyacumulado)-Number(props.totalcobradohoy)), 
 anteriores: Math.abs(Number(props.anterioresacumulado)-Number(props.anteriores))
}),
headers:{"content-type":"application/json"},
method:"post"
}


const consulta = await fetch("http://"+archivero.state.url+"/sg.php?ac=registrarcorte",parametros)
const respuesta = await consulta.json()
const estado = consulta.statusText.toString()

if(estado=='OK' && respuesta["resultado"]=='1'){    
Swal.fire({
  position: 'center',
  icon: 'success',
  title: 'Corte Registrado',
  showConfirmButton: false,
  timer: 1500
})
context.emit('obtenerproductos')
cambiarestado()
}
if(estado=='OK' && respuesta["resultado"]=='2'){
    
alert('Código repetido')

}
}
else{
  Swal.fire({
  position: 'center',
  icon: 'error',
  title: 'Faltan datos',
  showConfirmButton: false,
  timer: 1500
})
}


}





return{
cambiarestado,
procesar,
...toRefs(matrizdedatos),

}

}



})
