
import { ref } from 'vue'

const hoy=ref()
const tiempo = new Date()

hoy.value = (tiempo.getFullYear()).toString()+"-"+(tiempo.getMonth()+1).toString().padStart(2,"0")+"-"+("0"+tiempo.getDate()).toString().slice(-2)


export default {
hoy
}
