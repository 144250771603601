
import {  defineComponent} from "@vue/runtime-core";
import { ref} from "vue";




export default defineComponent({



setup(){

    const metros = ref('0')
    const pies = ref('0')
    const centimetros = ref('0')
    const pulgadas = ref('0')
    const yardas = ref('0')

    const metrosc = ref('0')
    const piesc = ref('0')
    const centimetrosc = ref('0')
    const pulgadasc = ref('0')
    const yardasc = ref('0')

   const Metros = () =>{
    pies.value = ((Number(Number(metros.value)*3.28084)).toFixed(6))
centimetros.value = ((Number(Number(metros.value)*100)).toFixed(6))
pulgadas.value = ((Number(Number(metros.value)*39.37008)).toFixed(6))
yardas.value = ((Number(Number(metros.value)*1.09361)).toFixed(6))
   }

   const Pies = () =>{
metros.value = ((Number(Number(pies.value)*3.28084)).toFixed(6))
centimetros.value = ((Number(Number(pies.value)*100)).toFixed(6))
pulgadas.value = ((Number(Number(pies.value)*39.37008)).toFixed(6))
yardas.value = ((Number(Number(pies.value)*1.09361)).toFixed(6))
   }


   const Centimetros = () =>{
    pies.value = ((Number(Number(centimetros.value)*0.0328084)).toFixed(6))
metros.value = ((Number(Number(centimetros.value)*0.01)).toFixed(6))
pulgadas.value = ((Number(Number(centimetros.value)*0.393701)).toFixed(6))
yardas.value = ((Number(Number(centimetros.value)*0.0109361)).toFixed(6))
   }

   const Pulgadas = () =>{
    pies.value = ((Number(Number(pulgadas.value)*0.0833333)).toFixed(6))
centimetros.value = ((Number(Number(pulgadas.value)*2.54)).toFixed(6))
metros.value = ((Number(Number(pulgadas.value)*0.0254)).toFixed(6))
yardas.value = ((Number(Number(pulgadas.value)*0.0277778)).toFixed(6))
   }

   const Yardas = () =>{
    metros.value = ((Number(Number(yardas.value)*0.9144)).toFixed(6))
    pies.value = ((Number(Number(yardas.value)*3)).toFixed(6))
centimetros.value = ((Number(Number(yardas.value)*91.44)).toFixed(6))
pulgadas.value = ((Number(Number(yardas.value)*36)).toFixed(6))

   }





   const Metrosc = () =>{
    piesc.value = ((Number(Number(metrosc.value)*10.7639)).toFixed(6))
centimetrosc.value = ((Number(Number(metrosc.value)*10000)).toFixed(6))
pulgadasc.value = ((Number(Number(metrosc.value)*1550)).toFixed(6))
yardasc.value = ((Number(Number(metrosc.value)*1.19599)).toFixed(6))
   }

   const Piesc = () =>{
    metros.value = ((Number(Number(piesc.value)*0.092903)).toFixed(6))
centimetrosc.value = ((Number(Number(piesc.value)*929.03)).toFixed(6))
pulgadasc.value = ((Number(Number(piesc.value)*144)).toFixed(6))
yardasc.value = ((Number(Number(piesc.value)*0.111111)).toFixed(6))
   }


   const Centimetrosc = () =>{
    piesc.value = ((Number(Number(centimetrosc.value)*0.00107639)).toFixed(6))
metrosc.value = ((Number(Number(centimetrosc.value)*0.0001)).toFixed(6))
pulgadasc.value = ((Number(Number(centimetrosc.value)*0.1554)).toFixed(6))
yardasc.value = ((Number(Number(centimetrosc.value)*0.000119599)).toFixed(6))
   }

   const Pulgadasc = () =>{
    piesc.value = ((Number(Number(pulgadasc.value)*0.00694444)).toFixed(6))
centimetrosc.value = ((Number(Number(pulgadasc.value)*6.4516)).toFixed(6))
metrosc.value = ((Number(Number(pulgadasc.value)*0.00064516)).toFixed(6))
yardasc.value = ((Number(Number(pulgadasc.value)*0.000771605)).toFixed(6))
   }

   const Yardasc = () =>{
    metrosc.value = ((Number(Number(yardasc.value)*0.836127)).toFixed(6))
    piesc.value = ((Number(Number(yardasc.value)*9)).toFixed(6))
centimetrosc.value = ((Number(Number(yardasc.value)*8361.27)).toFixed(6))
pulgadasc.value = ((Number(Number(yardasc.value)*1296)).toFixed(6))

   }







return{

    metros, pies, centimetros, pulgadas, yardas,
    
    Metros, Pies, Centimetros, Pulgadas, Yardas,

    metrosc, piesc, centimetrosc, pulgadasc, yardasc,

    Metrosc, Piesc, Centimetrosc, Pulgadasc, Yardasc
}
}

})
