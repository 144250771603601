
import archivero from '@/store'

 

const obtenerempresa= async ():Promise<string[] | undefined> =>{
const parametros = {
  headers: {"content-type":"application/json"},
  method:"post"
}
const consulta = await fetch("https://api.utp.hn/sg.php?ac=empresa",parametros)
const respuesta = await consulta.json()

return respuesta[0]
}



const obtenerproductoid = async (e:string): Promise<unknown |undefined> =>{
const parametros = {
body:JSON.stringify({id:e}),
headers:{"content-type":"application/json"},
method: "post"
}

const consulta = await fetch("http://"+archivero.state.url+"/sg.php?ac=productoid",parametros)
const respuesta = await consulta.json()
const estado = consulta.statusText.toString()
if(estado=='OK'){
return respuesta
 
}

}
const obtenerclienteid = async (e:string): Promise<unknown | undefined> =>{
const parametros = {
body:JSON.stringify({id:e}),
headers:{"content-type":"application/json"},
method: "post"
}
const consulta = await fetch("http://"+archivero.state.url+"/sg.php?ac=clienteid",parametros)
const respuesta = await consulta.json()
const estado = consulta.statusText.toString()
if(estado=='OK'){
return respuesta
 }
}

const obtenerproveedorid = async (e:string): Promise<unknown | undefined> =>{
const parametros = {
body:JSON.stringify({id:e}),
headers:{"content-type":"application/json"},
method: "post"
}
const consulta = await fetch("http://"+archivero.state.url+"/sg.php?ac=proveedorid",parametros)
const respuesta = await consulta.json()
const estado = consulta.statusText.toString()
if(estado=='OK'){
return respuesta
 }
}


const obtenercategoriaid = async (e:string): Promise<unknown | undefined> =>{
const parametros = {
body:JSON.stringify({id:e}),
headers:{"content-type":"application/json"},
method: "post"
}
const consulta = await fetch("http://"+archivero.state.url+"/sg.php?ac=categoriaid",parametros)
const respuesta = await consulta.json()
const estado = consulta.statusText.toString()
if(estado=='OK'){
return respuesta
 }
}
const obtenerproductocodigo = async (e:string): Promise<unknown | undefined> =>{
const parametros = {
body:JSON.stringify({id:e.trim()}),
headers:{"content-type":"application/json"},
method: "post"
}

const consulta = await fetch("http://"+archivero.state.url+"/sg.php?ac=productocodigo",parametros)
const respuesta = await consulta.json()
const estado = consulta.statusText.toString()
if(estado=='OK'){
    
return respuesta
 
}

}

const obtenerclientenombre = async (e:string) =>{
const parametros = {
body:JSON.stringify({criterio:e.trim()}),
headers:{"content-type":"application/json"},
method: "post"
}

const consulta = await fetch("http://"+archivero.state.url+"/sg.php?ac=cliente",parametros)
const respuesta = await consulta.json()
const estado = consulta.statusText.toString()
if(estado=='OK'){    
return respuesta
 
}

}






const obtenerproveedornombre = async (e:string) =>{
const parametros = {
body:JSON.stringify({criterio:e.trim()}),
headers:{"content-type":"application/json"},
method: "post"
}

const consulta = await fetch("http://"+archivero.state.url+"/sg.php?ac=proveedor",parametros)
const respuesta = await consulta.json()
const estado = consulta.statusText.toString()
if(estado=='OK'){    
return respuesta
 
}

}











const obtenerproductodescripcion = async (e:string) =>{
const parametros = {
body:JSON.stringify({descripcion:e.trim()}),
headers:{"content-type":"application/json"},
method: "post"
}

const consulta = await fetch("http://"+archivero.state.url+"/sg.php?ac=productodescripcion",parametros)
const respuesta = await consulta.json()
return respuesta


}



export default {
  categoriaid:obtenercategoriaid, 
  clienteid: obtenerclienteid, 
  proveedorid: obtenerproveedorid, 
  productoid:obtenerproductoid, 
  codigo: obtenerproductocodigo, 
  descripcion: obtenerproductodescripcion, 
  clientenombre:obtenerclientenombre,
   proveedornombre: obtenerproveedornombre,
   empresa: obtenerempresa
   }

