
import { Options, Vue } from 'vue-class-component';
import Corte from '@/components/corte.vue'; // @ is an alias to /src

@Options({
  components: {
    Corte,
  },
})
export default class Home extends Vue {}
