
import Menu from '@/views/menu.vue'
import {useRouter} from 'vue-router'
import { defineComponent, onBeforeMount, ref, computed, watchEffect } from '@vue/runtime-core'

export default defineComponent({
components:{Menu},
setup(){


const rutas = useRouter()

 onBeforeMount(()=>{
  
const sesion = computed(()=>{  
 return sessionStorage.getItem("sesion")
 })

if(!sesion.value){

rutas.push("sesion")
}

  })

}
})
