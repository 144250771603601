
import { defineComponent } from "@vue/runtime-core";
import clientes from '@/components/clientes.vue'

export default defineComponent ({
  components: { clientes },

setup(){

    return{}
}
})
