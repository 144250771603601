
import { defineComponent } from "@vue/runtime-core";
import Swal from "sweetalert2";

export default defineComponent({

name: "Menu",
setup(){




    return{
    
    }
}
})
