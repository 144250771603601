
import {  defineComponent, onMounted, ref } from "@vue/runtime-core";
import obtener from "@/components/metodos/obtener.vue"
import { toRefs } from "vue";
export default defineComponent({
props:{
    tipotitulo:String,
    
}, 
setup(){
const {empresa} = obtener
const datosempresa = ref<any>()

onMounted(async ()=>{
   datosempresa.value =  await empresa()
})


return{
    datosempresa
    }
}
})
