
import { defineComponent, onMounted } from "@vue/runtime-core";
import { getCurrentInstance, ref } from "vue";
import tiempo from "@/components/metodos/tiempo.vue"
import archivero from "@/store"
export default defineComponent({

name:"cobros",


setup(){

const cobros = ref()
const cobros2 = ref()
const estado = ref(false)
const id = ref()
const totalhoy = ref("0.00")
const totalotrosdias = ref("0.00")

const fecha1 = ref()
const fecha2 = ref()
const {hoy} = tiempo
fecha1.value = hoy.value
fecha2.value = hoy.value


const obtenercobros = async () =>{
const parametros = {
body:JSON.stringify({fecha1:fecha1.value,fecha2:fecha2.value}),
headers:{"content-type":"application/json"},
method: "post"
}

const consulta = await fetch("http://"+archivero.state.url+"/sg.php?ac=notasdeventalineas",parametros)
const respuesta = await consulta.json() 
const estado = consulta.statusText.toString()

if(estado=='OK'){
const datos = respuesta.sort( (a: any, b:any)=>Number(b.cantidad)-Number(a.cantidad)) 
cobros.value = datos
cobros2.value = datos
totalhoy.value = (cobros.value.map((e: typeof cobros.value)=>e.total).reduce(
    (anterior:number, actual:number)=>(Number(anterior)+Number(actual)),0)).toFixed(2)

}
}


const cobrospaginados = ref()

const Obtenerventas = ()=>{
cobrospaginados.value=[]
obtenercobros().then(()=>{
cobrospaginados.value=  cobros.value.filter((elemento: any)=>cobros.value.indexOf(elemento)<10)
MetodoBusquedaCodigo()
})
}

onMounted( async()=>{
  const instancia = getCurrentInstance()
  instancia!.appContext.config.globalProperties.hola

Obtenerventas()


})



const cambiarpaginacion = (index: number) =>{
    
  cobrospaginados.value=  cobros.value.filter((elemento: any)=>
  cobros.value.indexOf(elemento)>=10*(index) && cobros.value.indexOf(elemento)<10*(index+1))
}

const BusquedaCodigo = ref()

const MetodoBusquedaCodigo = () =>{

if(BusquedaCodigo.value!="" && BusquedaCodigo.value?.charAt(0)=="/")
{


cobros.value = cobros2.value.filter((e:any)=>e.codigo?.toUpperCase().match(BusquedaCodigo.value?.slice(1).toUpperCase()))
cobrospaginados.value=  cobros.value.filter((elemento: any)=>cobros.value.indexOf(elemento)<10)
totalhoy.value = (cobros.value.map((e: typeof cobros.value)=>e.total).reduce(
    (anterior:number, actual:number)=>(Number(anterior)+Number(actual)),0)).toFixed(2)
    
}

if(BusquedaCodigo.value!="" && BusquedaCodigo.value?.charAt(0)!="/")

{
cobros.value = cobros2.value.filter((e:any)=>e.descripcion?.toUpperCase().match(BusquedaCodigo.value?.toUpperCase()))
cobrospaginados.value=  cobros.value.filter((elemento: any)=>cobros.value.indexOf(elemento)<10)
totalhoy.value = (cobros.value.map((e: typeof cobros.value)=>e.total).reduce(
    (anterior:number, actual:number)=>(Number(anterior)+Number(actual)),0)).toFixed(2)
    
}
if(BusquedaCodigo.value=="")
{
  cobros.value = cobros2.value
cobrospaginados.value=  cobros.value.filter((elemento: any)=>cobros.value.indexOf(elemento)<10)
totalhoy.value = (cobros.value.map((e: typeof cobros.value)=>e.total).reduce(
    (anterior:number, actual:number)=>(Number(anterior)+Number(actual)),0)).toFixed(2)
}
}


const ordenarporcantidad = () =>{
cobrospaginados.value = cobrospaginados.value.sort((a: any, b:any)=>Number(b.cantidad)-Number(a.cantidad))

}

const ordenarportotal = () =>{
cobrospaginados.value = cobrospaginados.value.sort((a: any, b:any)=>Number(b.total)-Number(a.total))
  
}
const ordenarporpreciodeventa = () =>{
cobrospaginados.value = cobrospaginados.value.sort((a: any, b:any)=>Number(b.precio)-Number(a.precio))
  
}

return{
ordenarporpreciodeventa,
ordenarporcantidad,
ordenarportotal,
BusquedaCodigo,
MetodoBusquedaCodigo,
fecha1,fecha2,
cambiarpaginacion,
cobrospaginados,
cobros,
estado,
totalhoy,
totalotrosdias,
Obtenerventas,
id,

}
}

})
