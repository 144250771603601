

import {  defineComponent,SetupContext} from "@vue/runtime-core";
import archivero from '@/store'
import { ref } from "vue";

export default defineComponent({
name: "Buscar Producto",
props:{
estado:Boolean,
idrecibido: Number,
},
emits:{
cambiarestado : Function,

},

setup(props ,context:SetupContext){

const cambiarestado = ()=>{   
context.emit('cambiarestado')
}



const comentarios = ref()
const anular = async ()=>{
const parametros = {
method:"post",
body: JSON.stringify({iddocumento:props.idrecibido, comentarios:comentarios.value?comentarios.value:""}),
headers: {"content-type":"application/json"}
}
const consulta = await fetch("http://"+archivero.state.url+"/sg.php?ac=anularnotadeventa",parametros)
const respuesta = await consulta.json()
if(respuesta["resultado"]==1){
    alert("Anulada")
    cambiarestado()
}else{
    alert("error")
}
}


return{
cambiarestado,
anular,
comentarios
}
}

})
