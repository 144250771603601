
import { defineComponent } from "@vue/runtime-core";
import proveedores from '@/components/proveedores.vue'

export default defineComponent ({
  components: { proveedores },

setup(){

    return{}
}
})
