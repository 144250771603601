
import { defineComponent } from "@vue/runtime-core";
import productos from '@/components/nuevanotadecompra.vue'

export default defineComponent ({
  name:"NNDV",
  components: { productos },

setup(){

    return{}
}
})
