
import {  defineComponent,nextTick, onBeforeMount, onMounted } from "@vue/runtime-core";
import {  ref } from "vue";
import Cobros from "@/components/metodos/formulariocobros.vue";
import Anular from "@/components/metodos/formularioanularnota.vue";
import archivero from "@/store"
import { useRouter } from "vue-router";
import tiempo from "@/components/metodos/tiempo.vue"

export default defineComponent({

components: {
  Cobros, Anular
  },

setup(props,context){


const idrecibido = ref(0)
const estadocobros = ref(false)
const estadoanular = ref(false)
const filtradoporestado = ref(0)
const { hoy } = tiempo


const clientespaginados = ref()
const busqueda = ref()

const ultimoindex= ref(0)
const totalfinal = ref("0.00")
const productos = ref()
const productosrespaldo = ref()
const fecha1 = ref()
const fecha2 = ref()
const spin = ref(false)
const estado = ref(false)
const id = ref()
const total = ref('0.00')
const estadodetalledelineas = ref(false)
const productoslineas = ref([])
const ClienteBusqueda = ref('')

onBeforeMount(()=>{

if(archivero.state.fecha1==''){
  archivero.commit({
  type: "Parametros",
  fecha1: hoy.value, 
  fecha2: hoy.value,
})


fecha1.value=hoy.value
fecha2.value=hoy.value
}else{
fecha1.value=archivero.state.fecha1
fecha2.value=archivero.state.fecha2

}


if(archivero.state.cliente!=""){
ClienteBusqueda.value=archivero.state.cliente
}





})


//metodo de filtrado 
const MetodoPaginado = () => {
clientespaginados.value=  productos.value.filter((elemento: typeof productos.value)=>productos.value.indexOf(elemento)<10)
}
//Obtener notas
const obtenernotas = async () =>{
archivero.commit({
  type:"Parametros",
  fecha1: fecha1.value,
  fecha2: fecha2.value,  
})
const parametros = {
body:JSON.stringify({fecha1:fecha1.value,fecha2:fecha2.value}),
headers:{"content-type":"application/json"},
method: "post"
}
const consulta = await fetch("http://"+archivero.state.url+"/sg.php?ac=notasdeventa",parametros)
const respuesta = await consulta.json()
const estado = consulta.statusText.toString()

if(estado=='OK'){
productos.value = respuesta
productosrespaldo.value = productos.value

MetodoPaginado()
//Retorno la promesa de resultado
if(respuesta.length>0){
  
return Promise.resolve("1")
}else{

  return Promise.resolve("0")
}
}

}





//Funcion para actualizar el total despues de filtrar el estado
const actualizartotal = () =>{
  const array: string[]= []
productos.value.forEach((element:any) => {
  array.push(element["total"])
});
total.value= Number(array.reduce((anterior:unknown,actual:unknown)=>{
  return Number(anterior)+Number(actual)
},0)).toFixed(2).toString()

}
 //Funcion para filtrar por estado
const cambiarfiltradoporestado = () =>{
if(filtradoporestado.value!==0){
ultimoindex.value=0

if(filtradoporestado.value<3){
productos.value = productosrespaldo.value.filter((elemento: typeof productosrespaldo.value)=>elemento.estado==1 || elemento.estado==2 && elemento.cliente.match(ClienteBusqueda.value.toUpperCase()))
actualizartotal()
MetodoPaginado()
}

if(filtradoporestado.value==3){
productos.value = productosrespaldo.value.filter((elemento: typeof productosrespaldo.value)=>elemento.estado==3 && elemento.cliente.match(ClienteBusqueda.value.toUpperCase()))
actualizartotal()
MetodoPaginado()
}

}





else{
productos.value = productosrespaldo.value.filter((elemento: typeof productosrespaldo.value)=>elemento.cliente.match(ClienteBusqueda.value.toUpperCase()))
actualizartotal()
MetodoPaginado()  
}
}

//Funcion para obtener productos y pasar metodos con filtros
const obtenercompleta = async():Promise<string> =>{
const OB = obtenernotas().then((e: any)=>{
if(e=="1"){
clientespaginados.value=  productos.value.filter((elemento: typeof productos.value)=>productos.value.indexOf(elemento)<10)
const array = productos.value.map((e: typeof productos.value)=>e.total)
total.value= Number(array.reduce((anterior:unknown,actual:unknown)=>{
  return Number(anterior)+Number(actual)
},0)).toFixed(2).toString()
  cambiarfiltradoporestado() 
  
}

return e

})
//Retorno la promesa de resultado
return Promise.resolve(OB)   


 } 
onMounted( async()=>{
  const index = archivero.state.index
//Esperamos la promesa de resultado
obtenercompleta().then((e: string)=>{
  
if(Number(e)>0){
 
//Confirmamos que se lleno el index en el archivero
 if(archivero.state.index!=999999){

  cambiarpaginacion(index)
 
  }
}

})

})





const cambiarpaginacion = (index: number) =>{
archivero.commit({
  type:"Index", 
  index: index,
})

ultimoindex.value = index  
clientespaginados.value=  productos.value.filter((elemento: any)=>
productos.value.indexOf(elemento)>=10*(ultimoindex.value) && productos.value.indexOf(elemento)<10*(ultimoindex.value+1))


}
const cambiarestadocobros = (e: number) =>{
estadocobros.value = !estadocobros.value
if(estadocobros.value==true){
const montoacobrarelemento = document.getElementById("montoacobrar") as HTMLInputElement
nextTick(()=>{
montoacobrarelemento.focus()
 
})


idrecibido.value = e as number
}
if(estadocobros.value==false){


cambiarspin()
obtenercompleta().then((e:any)=>{

    if(e=="1" && ClienteBusqueda.value!=""){
   
 clientespaginados.value=  productos.value.filter((elemento: any)=>
  productos.value.indexOf(elemento)>=10*(ultimoindex.value) && productos.value.indexOf(elemento)<10*(ultimoindex.value+1))
cambiarspin()
  }else{
     clientespaginados.value=  productos.value.filter((elemento: any)=>
  productos.value.indexOf(elemento)>=10*(ultimoindex.value) && 
  productos.value.indexOf(elemento)<10*(ultimoindex.value+1))
cambiarspin()
  }

})
}
}

const cambiarestadoanular = (e: number) =>{

estadoanular.value = !estadoanular.value
if(estadoanular.value==true){
const montoacobrarelemento = document.getElementById("montoacobrar") as HTMLInputElement
nextTick(()=>{
montoacobrarelemento.focus()
 
})

idrecibido.value = e as number
}
if(estadoanular.value==false){

cambiarspin()
obtenercompleta().then((e:any)=>{

    if(e=="1" && ClienteBusqueda.value!=""){
   
 clientespaginados.value=  productos.value.filter((elemento: any)=>
  productos.value.indexOf(elemento)>=10*(ultimoindex.value) && productos.value.indexOf(elemento)<10*(ultimoindex.value+1))
cambiarspin()
  }else{
     clientespaginados.value=  productos.value.filter((elemento: any)=>
  productos.value.indexOf(elemento)>=10*(ultimoindex.value) && 
  productos.value.indexOf(elemento)<10*(ultimoindex.value+1))
cambiarspin()
  }

})
}
}



const cambiarspin = () =>{
spin.value = !spin.value

}

const cambiarestadodetalledelineas = () =>{
estadodetalledelineas.value = !estadodetalledelineas.value

}

const cambiarestado = (e: string) =>{
estado.value = !estado.value
id.value = e
if(id.value!='' && estado.value==true){
cambiarspin()
}


}


const mostrar = async (e: any) =>{
const id = e
productoslineas.value=[]
totalfinal.value="0.00"
async function funcion(){
const parametros = {
body:JSON.stringify({iddocumento:id}),
headers:{"content-type":"application/json"},
method: "post"
}
const consulta = await fetch("http://"+archivero.state.url+"/sg.php?ac=notadeventalineas",parametros)
const respuesta = await consulta.json()
productoslineas.value = respuesta 
totalfinal.value= respuesta[0]["totalfinal"]
 }
funcion().then(function(){
  estadodetalledelineas.value=true

})


}

//Ver detalle de nota de venta
const rutas = useRouter();
const nuevaventa = () =>{
    rutas.push({
        name: 'NuevaNotadeCompra'       
      }) 
}

const abrirdocumento = async(e: string) =>{
async function primera(){
archivero.commit({
  type: 'Documento',
  elemento: e
})
}

primera().then(()=>{
nuevaventa()

})



}


const MetodoBusquedaCliente = () =>{
archivero.commit({
type: "Cliente",
cliente: ClienteBusqueda.value
})


const resultado = productosrespaldo.value.filter((e: typeof productosrespaldo.value)=>
e.cliente.match(ClienteBusqueda.value.toUpperCase()))

if(filtradoporestado.value==0){
clientespaginados.value=resultado
productos.value = clientespaginados.value
actualizartotal()
}else{
clientespaginados.value=resultado.filter((e: typeof productosrespaldo.value)=>
  e.estado==filtradoporestado.value && e.cliente.match(ClienteBusqueda.value.toUpperCase()))

productos.value = clientespaginados.value
actualizartotal()
}




}


return{

ClienteBusqueda,
MetodoBusquedaCliente,
abrirdocumento,
nuevaventa,
filtradoporestado,
cambiarfiltradoporestado,
cambiarestadocobros,
estadocobros,
idrecibido,
totalfinal,
obtenercompleta,
mostrar,
productoslineas,
cambiarestadodetalledelineas,
estadodetalledelineas,
estado,
total,
fecha1,fecha2,
cambiarpaginacion,
clientespaginados,
spin,
cambiarspin,
productos,
cambiarestado,
cambiarestadoanular,
obtenernotas,
id,
estadoanular,
busqueda,
hoy,
}
}

})
