
import {defineComponent, nextTick, watchEffect} from "@vue/runtime-core";
import {reactive, Ref, ref, toRefs } from "vue";
import archivero from "@/store"
import Swal from "sweetalert2";
import cliente from "@/components/metodos/obtener.vue";
export default defineComponent({
props:{
    estado:Boolean,
    id: String,
    spin: Boolean,
},
emits:{
    cambiarestado:Function,
    obtenerproductos:Function,
    cambiarspin:Function,
},

directives: {

mayuscula: {

     beforeUpdate: function(el: Ref ){
        nextTick(()=>{
        const valor =el.value.toUpperCase()
        el.value= valor
        
        })
        
    }
  },

},
setup(props, context){


const matrizdedatos = reactive({
    formulario:{
    cliente: "",
    telefono: "",
    rtn: "",
    contacto: "",
}
})

const impuestos = ref({})

const categorias = ref({})



const {clienteid} = cliente


const Obtenercliente = (e:string)=>{
const respuesta = clienteid(e)

respuesta.then((respuesta:any)=>{

matrizdedatos.formulario.cliente=respuesta[0]["cliente"]
matrizdedatos.formulario.telefono=respuesta[0]["telefono"]
matrizdedatos.formulario.rtn=respuesta[0]["rtn"]
matrizdedatos.formulario.contacto=respuesta[0]["contacto"]



})






  setTimeout(()=>{
context.emit('cambiarspin')

},1000)
 


}


watchEffect(() =>{
  
      

     if(props.id){

      Obtenercliente(props.id)
       
     }
if(props.estado==true){
   nextTick(()=>{
   const cliente = document.getElementById("cliente") as HTMLInputElement
     cliente.focus()
   })
 
}     

})











const cambiarestado = ()=>{

  const vaciar = async() =>{
    matrizdedatos.formulario.cliente= ""
    matrizdedatos.formulario.telefono=""
    matrizdedatos.formulario.rtn=""
    matrizdedatos.formulario.contacto=""
  }
  vaciar().then(()=>{
  context.emit('cambiarestado')
  
  })
    



}











const procesar = async () =>{
if( matrizdedatos.formulario.cliente!= "" &&
    matrizdedatos.formulario.telefono!=""  ){
const parametros = {
body: JSON.stringify({cliente:matrizdedatos.formulario.cliente, 
telefono: matrizdedatos.formulario.telefono, contacto: matrizdedatos.formulario.contacto, rtn: matrizdedatos.formulario.rtn
}),
headers:{"content-type":"application/json"},
method:"post"
}


const consulta = await fetch("http://"+archivero.state.url+"/sg.php?ac=registrarcliente",parametros)
const respuesta = await consulta.json()
const estado = consulta.statusText.toString()

if(estado=='OK' && respuesta["resultado"]=='1'){    
Swal.fire({
  position: 'center',
  icon: 'success',
  title: 'Cliente registrado',
  showConfirmButton: false,
  timer: 1500
})
context.emit('obtenerproductos')
cambiarestado()
}
if(estado=='OK' && respuesta["resultado"]=='2'){
    
alert('Código repetido')

}
}
else{
  Swal.fire({
  position: 'center',
  icon: 'error',
  title: 'Faltan datos',
  showConfirmButton: false,
  timer: 1500
})
}


}

const procesaractualizacion = async () =>{
const parametros = {
body: JSON.stringify({
cliente:matrizdedatos.formulario.cliente, 
telefono: matrizdedatos.formulario.telefono,
contacto: matrizdedatos.formulario.contacto,
rtn: matrizdedatos.formulario.rtn,
id: props.id


}),
headers:{"content-type":"application/json"},
method:"post"
}


const consulta = await fetch("http://"+archivero.state.url+"/sg.php?ac=actualizarcliente",parametros)
const respuesta = await consulta.json()
const estado = consulta.statusText.toString()

if(estado=='OK'){
    
Swal.fire({
  position: 'center',
  icon: 'success',
  title: 'Proveedor actualizado',
  showConfirmButton: false,
  timer: 1500
})

context.emit('obtenerproductos')
context.emit('cambiarestado')
}else{
    
alert('Problemas de conexión a la base datos')
context.emit('cambiarestado')
}
}
const funciontelefono = () =>{
  matrizdedatos.formulario.telefono="0000-0000"
}

const funcioncontacto = () =>{

  matrizdedatos.formulario.contacto= matrizdedatos.formulario.cliente
}

const funcionrtn = () =>{

matrizdedatos.formulario.rtn = Math.floor(Math.random()*999999999999).toString()

}



return{
cambiarestado,
impuestos,
procesar,
procesaractualizacion,
categorias,
...toRefs(matrizdedatos),
funcionrtn,
funcioncontacto,
funciontelefono
}

}



})
