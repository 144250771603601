
import {defineComponent, nextTick, onMounted, watchEffect} from "@vue/runtime-core";
import {getCurrentInstance, reactive, ref, toRefs } from "vue";
import archivero from "@/store"
import Swal from "sweetalert2";
import producto from "@/components/metodos/obtener.vue";

import JsBarcode from "jsbarcode";

export default defineComponent({
props:{
    estado:Boolean,
    id: String,
    spin: Boolean,
},
emits:{
    cambiarestado:Function,
    obtenerproductos:Function,
    cambiarspin:Function,
},

setup(props, context){

const matrizdedatos = reactive({
    formulario:{
    descripcion: "",
    codigo: "",
    codigodebarra: "",
    impuesto:"",
    tarifa1: "",
    tarifa2: "",
    tipo:false,
    categoria:"",
    estocaje:"",
}
})

const impuestos = ref({})

const categorias = ref({})


const obtenerimpuestos = async() =>{
const parametros = {
headers:{"content-type":"application/json"},
method:"post"
}


const consulta = await fetch("http://"+archivero.state.url+"/sg.php?ac=impuestos",parametros)
const respuesta = await consulta.json()
const estado = consulta.statusText
if(estado=='OK'){
    impuestos.value=respuesta
}else{
    
alert('Problemas de conexión a la base datos')
context.emit('cambiarestado')
}


}

const obtenercategorias = async() =>{
const parametros = {
headers:{"content-type":"application/json"},
method:"post"
}





const consulta = await fetch("http://"+archivero.state.url+"/sg.php?ac=categorias",parametros)
const respuesta = await consulta.json()
const estado = consulta.statusText
if(estado=='OK'){
    categorias.value=respuesta
}else{
    
alert('Problemas de conexión a la base datos')
context.emit('cambiarestado')
}


}
const {productoid} = producto


const Obtenerproducto = (e:string)=>{
const respuesta = productoid(e)

respuesta.then((respuesta:any)=>{

matrizdedatos.formulario.descripcion=respuesta[0]["descripcion"]
matrizdedatos.formulario.codigo=respuesta[0]["codigo"]
matrizdedatos.formulario.codigodebarra=respuesta[0]["codigodebarra"]
matrizdedatos.formulario.impuesto=respuesta[0]["impuestoid"]
matrizdedatos.formulario.categoria=respuesta[0]["categoriaid"]
matrizdedatos.formulario.tarifa1=respuesta[0]["tarifa1"]
matrizdedatos.formulario.tarifa2=respuesta[0]["tarifa2"]
matrizdedatos.formulario.estocaje=respuesta[0]["estocaje"]
matrizdedatos.formulario.tipo=respuesta[0]["tipo"]=='1'?true:false

JsBarcode(".barcode", respuesta[0]["codigodebarra"], {

  displayValue: true
});
})






  setTimeout(()=>{
context.emit('cambiarspin')

},1000)
 


}


watchEffect(() =>{
    if(props.estado==true){
        obtenercategorias()
        obtenerimpuestos()
    }

   

     if(props.id){

      Obtenerproducto(props.id)
     

      
     }
     

})










const cambiarestado = ()=>{

  const vaciar = async() =>{
    matrizdedatos.formulario.descripcion= ""
    matrizdedatos.formulario.codigo=""
    matrizdedatos.formulario.codigodebarra= ""
    matrizdedatos.formulario.impuesto=""
    matrizdedatos.formulario.tarifa1= ""
    matrizdedatos.formulario.tarifa2= ""
    matrizdedatos.formulario.tipo=false
    matrizdedatos.formulario.categoria=""
    matrizdedatos.formulario.estocaje=""
  }
  vaciar().then(()=>{
  context.emit('cambiarestado')
  
context.emit('obtenerproductos')
  })
    



}



const cambiartipo = () =>{

nextTick(()=>{
   if(matrizdedatos.formulario.tipo==true){
      
      matrizdedatos.formulario.estocaje='0'
      matrizdedatos.formulario.categoria='1'

    }else if(matrizdedatos.formulario.tipo==false){

      matrizdedatos.formulario.estocaje=''
      matrizdedatos.formulario.categoria=''
    }
    })
}







const procesar = async () =>{
if( matrizdedatos.formulario.descripcion!= "" &&
    matrizdedatos.formulario.codigo!="" &&
    matrizdedatos.formulario.codigodebarra!= "" &&
    matrizdedatos.formulario.impuesto!="" &&
    matrizdedatos.formulario.tarifa1!= "" &&
    matrizdedatos.formulario.tarifa2!= "" &&
    matrizdedatos.formulario.categoria!="" &&
    matrizdedatos.formulario.estocaje!= ""){
const parametros = {
body: JSON.stringify({descripcion:matrizdedatos.formulario.descripcion.toUpperCase(), codigo: matrizdedatos.formulario.codigo.toUpperCase(),
codigodebarra: matrizdedatos.formulario.codigodebarra, tarifa1: matrizdedatos.formulario.tarifa1,
tarifa2: matrizdedatos.formulario.tarifa2,
impuesto: matrizdedatos.formulario.impuesto, categoria: matrizdedatos.formulario.categoria,
tipo: matrizdedatos.formulario.tipo, estocaje: matrizdedatos.formulario.estocaje
}),
headers:{"content-type":"application/json"},
method:"post"
}


const consulta = await fetch("http://"+archivero.state.url+"/sg.php?ac=registrarproducto",parametros)
const respuesta = await consulta.json()
const estado = consulta.statusText.toString()

if(estado=='OK' && respuesta["resultado"]=='1'){    
Swal.fire({
  position: 'center',
  icon: 'success',
  title: 'Producto registrado',
  showConfirmButton: false,
  timer: 1500
})
context.emit('obtenerproductos')
cambiarestado()
}
if(estado=='OK' && respuesta["resultado"]=='2'){
    
alert('Código repetido')

}
}
else{
  Swal.fire({
  position: 'center',
  icon: 'error',
  title: 'Faltan datos',
  showConfirmButton: false,
  timer: 1500
})
}


}

const procesaractualizacion = async () =>{
const parametros = {
body: JSON.stringify({descripcion:matrizdedatos.formulario.descripcion.toUpperCase(), 
codigo: matrizdedatos.formulario.codigo.toUpperCase(),
codigodebarra: matrizdedatos.formulario.codigodebarra.toUpperCase(), 
tarifa1: matrizdedatos.formulario.tarifa1,
tarifa2: matrizdedatos.formulario.tarifa2,
impuesto: matrizdedatos.formulario.impuesto, 
categoria: matrizdedatos.formulario.categoria,
tipo: matrizdedatos.formulario.tipo, 
estocaje: matrizdedatos.formulario.estocaje, 
 id:props.id
}),
headers:{"content-type":"application/json"},
method:"post"
}


const consulta = await fetch("http://"+archivero.state.url+"/sg.php?ac=actualizarproducto",parametros)
const respuesta = await consulta.json()


const estado = consulta.statusText.toString()
if(estado=='OK'){

  if(respuesta["resultado"]=="1"){
Swal.fire({
  position: 'center',
  icon: 'success',
  title: 'Procesado',
  showConfirmButton: false,
  timer: 1500
})

context.emit('obtenerproductos')
}
if(respuesta["resultado"]=="2"){

  Swal.fire({
  position: 'center',
  icon: 'error',
  title: 'Código duplicado',
  showConfirmButton: false,
  timer: 1500
})
context.emit('obtenerproductos')
}

if(respuesta["resultado"]=="0"){

  Swal.fire({
  position: 'center',
  icon: 'success',
  title: 'Procesado',
  showConfirmButton: false,
  timer: 1500
})
  

context.emit('obtenerproductos')
}

}

else{
    
alert('Problemas de conexión a la base datos')
context.emit('cambiarestado')
}
}







const procesarduplicado = async () =>{
const parametros = {
body: JSON.stringify({descripcion:matrizdedatos.formulario.descripcion.toUpperCase(), 
codigo: (matrizdedatos.formulario.codigo+"1").toUpperCase(),
codigodebarra: (matrizdedatos.formulario.codigodebarra+"1").toUpperCase(), 
tarifa1: matrizdedatos.formulario.tarifa1,
tarifa2: matrizdedatos.formulario.tarifa2,
impuesto: matrizdedatos.formulario.impuesto, 
categoria: matrizdedatos.formulario.categoria,
tipo: matrizdedatos.formulario.tipo, 
estocaje: matrizdedatos.formulario.estocaje
}),
headers:{"content-type":"application/json"},
method:"post"
}


const consulta = await fetch("http://"+archivero.state.url+"/sg.php?ac=duplicarproducto",parametros)
const respuesta = await consulta.json()
const estado = consulta.statusText.toString()
matrizdedatos.formulario.codigo=matrizdedatos.formulario.codigo+"1"
matrizdedatos.formulario.codigodebarra=matrizdedatos.formulario.codigodebarra+"1"
if(estado=='OK'){
    
Swal.fire({
  position: 'center',
  icon: 'success',
  title: 'Procesado',
  showConfirmButton: false,
  timer: 1500
})

context.emit('obtenerproductos')
//context.emit('cambiarestado')

}else{
    
alert('Problemas de conexión a la base datos')
context.emit('cambiarestado')
}


}

const actualizarbarcode = () =>{ 
  
JsBarcode(".barcode", matrizdedatos.formulario.codigodebarra, {

displayValue: true
});
}

return{
actualizarbarcode,
cambiarestado,
impuestos,
procesar,
procesaractualizacion,
procesarduplicado,
categorias,
...toRefs(matrizdedatos),
cambiartipo,
}

}



})
