
import { defineComponent, inject } from "@vue/runtime-core";
import { ref } from "vue";
import obtener from "@/components/metodos/obtener.vue";
export default defineComponent({
name: "Buscar Producto",
props:{
estado:Boolean
},
emits:{
cambiarestado : Function,

},
setup(props,context){
const productos = ref([])
const busqueda = ref()
const Obtener = inject('Obtenerproducto')





const {codigo} = obtener



const obtenerproducto = () =>{
if(busqueda.value){
const respuesta = codigo(busqueda.value) 
respuesta.then((respuesta: any)=>{
productos.value = respuesta

})
}
}

const cambiarestado = ()=>{
context.emit('cambiarestado')
busqueda.value=""
}



return{
Obtener,
cambiarestado,
obtenerproducto,
busqueda,
productos
    }
}

})
