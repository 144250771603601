
import { defineComponent } from "@vue/runtime-core";
import productos from '@/components/notasdecompra.vue'

export default defineComponent ({
  
  components: { productos },

setup(){

    return{}
}
})
