
import {  defineComponent,nextTick,  onBeforeMount, onMounted, reactive, toRefs, watchEffect } from "@vue/runtime-core";
import {  getCurrentInstance, ref} from "vue";
import Cobros from "@/components/metodos/formulariocobros.vue";
import archivero from "@/store"
import { useRoute, useRouter } from "vue-router";
import tiempo from "@/components/metodos/tiempo.vue"
import Swal from "sweetalert2";


export default defineComponent({

components: {
  Cobros
  },

setup(){

const rutas = useRouter()
const ruta = useRoute()
const idrecibido = ref(0)
const idrecibidomultiple = ref()
const estadocobros = ref(false)

const estadocobrosmultiples = ref(false)
const estadoanular = ref(false)
const filtradoporestado = ref(0)
const { hoy } = tiempo

const Cobros = ref(0)

const tipotitulo = ref("")
const notaspaginadas = ref()
const busqueda = ref()

const ultimoindex= ref(0)
const totalfinal = ref("0.00")
const productos = ref()
const productosrespaldo = ref()
const fecha1 = ref()
const fecha2 = ref()

const estado = ref(false)
const id = ref()
const total = ref('0.00')
const estadodetalledelineas = ref(false)
const productoslineas = ref()
const ClienteBusqueda = ref('')

const arreglo = reactive({
  elementos: new Array <{id: string; monto: string}>()
})

onBeforeMount(()=>{

if(archivero.state.fecha1==''){
  archivero.commit({
  type: "Parametros",
  fecha1: hoy.value, 
  fecha2: hoy.value,
})


fecha1.value=hoy.value
fecha2.value=hoy.value
}else{
fecha1.value=archivero.state.fecha1
fecha2.value=archivero.state.fecha2

}


if(archivero.state.cliente!=""){
ClienteBusqueda.value=archivero.state.cliente
}

})


//metodo de filtrado 
const MetodoPaginado = () => {
notaspaginadas.value=  productos.value.filter((elemento: typeof productos.value)=>productos.value.indexOf(elemento)<1000)
}
//Obtener notas
const obtenernotas = async () =>{
total.value="0.00"
archivero.commit({
  type:"Parametros",
  fecha1: fecha1.value,
  fecha2: fecha2.value,  
})
const parametros = {
body:JSON.stringify({fecha1:fecha1.value,fecha2:fecha2.value}),
headers:{"content-type":"application/json"},
method: "post"
}

const consulta = await fetch("http://"+archivero.state.url+"/sg.php?ac=notasdeventacxc",parametros)
const respuesta = await consulta.json()

const estado = consulta.statusText.toString()

if(estado=='OK'){
productos.value = respuesta
productosrespaldo.value = productos.value

MetodoPaginado()
//Retorno la promesa de resultado
if(respuesta.length>0){
  return Promise.resolve("1")
}else{
  return Promise.resolve("0")
}
}

}





//Funcion para actualizar el total despues de filtrar el estado
const actualizartotal = () =>{

  const array = productos.value.map((elemento:any)=>{
    return elemento["total"]
  })


total.value= (Number(array.reduce((anterior:unknown,actual:unknown)=>{
  return Number(anterior)+Number(actual)
},0))-Number(Cobros.value)).toFixed(2)

}

const MetodoCobros = async (): Promise<number | undefined> =>{
const parametros = {
  headers:{"content-type":"application/json"},
  body:JSON.stringify({fecha1: fecha1.value, fecha2: fecha2.value}),
  method:"post"}
const consulta = await fetch("http://"+archivero.state.url+"/sg.php?ac=cobrospordiayventacxc",parametros)
const respuesta = await consulta.json()
Cobros.value = (respuesta.filter((elemento: any)=>{
return elemento.cliente.match(ClienteBusqueda.value.toUpperCase()) && elemento.estado<3
})).map((elemento:any)=>{
 return elemento["monto"]
}).reduce((anterior:any,actual:any)=>(Number(anterior)+Number(actual)).toString(),0)
if(consulta.statusText=="OK"){
  return Promise.resolve(1)
}
}
 



//Funcion para obtener productos y pasar metodos con filtros
const obtenercompleta = async():Promise<string> =>{
const OB = obtenernotas().then(async (e: any)=>{
if(await e=="1"){
notaspaginadas.value=  productos.value.filter((elemento: typeof productos.value)=>productos.value.indexOf(elemento)<1000)
const array = productos.value.map((e: typeof productos.value)=>e.total)
total.value= (Number(array.reduce((anterior:unknown,actual:unknown)=>{
return Number(anterior)+Number(actual)
},0))-Number(Cobros.value)).toFixed()  
}
return e
})
//Retorno la promesa de resultado
return Promise.resolve(OB)
} 

const funcionprimaria = ()=>{
  
  const index = archivero.state.index

//Esperamos la promesa de resultado
MetodoCobros().then(async (e:any)=>{
  if(await e==1){

obtenercompleta()

  }
})
}








//Cambiar numero de pagina en tabla
const CambiarPaginacion = (index: number) =>{

ultimoindex.value = index  
notaspaginadas.value=  productos.value.filter((elemento: any)=>
productos.value.indexOf(elemento)>=1000*(ultimoindex.value) && productos.value.indexOf(elemento)<1000*(ultimoindex.value+1))

MetodoChequeo()


}

const cambiarestadocobros = (e: string) =>{
estadocobros.value = !estadocobros.value
if(estadocobros.value==true){
const montoacobrarelemento = document.getElementById("recibido") as HTMLInputElement
nextTick(()=>{
montoacobrarelemento.focus()
})
idrecibido.value = Number(e) as number
}
if(estadocobros.value==false){

  
MetodoCobros().then((e: any)=>{


  if(e==1){

obtenernotas().then(()=>
{

  const resultado = productosrespaldo.value.filter((e: typeof productosrespaldo.value)=>
e.cliente.match(ClienteBusqueda.value.toUpperCase()))
productos.value = resultado
  if(notaspaginadas.value.length>=1000){
CambiarPaginacion(ultimoindex.value)

}else{
  
  CambiarPaginacion(0)



}
})
actualizartotal()




}
})


}



}


const cambiarestadoanular = (e: number) =>{

estadoanular.value = !estadoanular.value
if(estadoanular.value==true){
const montoacobrarelemento = document.getElementById("comentarios") as HTMLInputElement
nextTick(()=>{
montoacobrarelemento.focus()
 
})

idrecibido.value = e as number
}
if(estadoanular.value==false){


obtenercompleta().then((e:any)=>{

    if(e=="1" && ClienteBusqueda.value!=""){
   
 notaspaginadas.value=  productos.value.filter((elemento: any)=>
  productos.value.indexOf(elemento)>=1000*(ultimoindex.value) && productos.value.indexOf(elemento)<1000*(ultimoindex.value+1))

  }else{
     notaspaginadas.value=  productos.value.filter((elemento: any)=>
  productos.value.indexOf(elemento)>=1000*(ultimoindex.value) && 
  productos.value.indexOf(elemento)<1000*(ultimoindex.value+1))

  }

})
}
}




const cambiarestadodetalledelineas = () =>{
estadodetalledelineas.value = !estadodetalledelineas.value

}

const cambiarestado = (e: string) =>{
estado.value = !estado.value
id.value = e



}
const formasdepago = ref()
const obtenerformasdepago = async () =>{

const parametros = {
    method:"post",
    headers:{"content-type":"application/json"}
}
const consulta = await fetch("http://api.utp.hn/sg.php/sg.php?ac=formasdepago",parametros)
const respuesta = await consulta.json()
formasdepago.value = respuesta


}

const mostrar = async (e: any) =>{
const id = e
productoslineas.value=[]
totalfinal.value="0.00"
async function funcion(){
const parametros = {
body:JSON.stringify({iddocumento:id}),
headers:{"content-type":"application/json"},
method: "post"
}
const consulta = await fetch("http://"+archivero.state.url+"/sg.php?ac=notadeventalineas",parametros)
const respuesta = await consulta.json()
productoslineas.value = respuesta 
totalfinal.value= respuesta[0]["totalfinal"]
 }
funcion().then(function(){
  estadodetalledelineas.value=true

})


}
onBeforeMount(()=>{
  obtenerformasdepago()
})

onMounted(()=>{

 funcionprimaria()
})
//Ver detalle de nota de venta
const nuevaventa = () =>{

   archivero.commit({
     type:"Documento",
     elemento: ""
   })

    rutas.push({
         name: 'NuevaVenta',params:{tipo:ruta.params.tipo}     
      }) 


}


const abrirdocumento = async(e: string, tipo: string) =>{
async function primera(){
archivero.commit({
  type: 'Documento',
  elemento: e
})
}
  
primera().then(()=>{

 rutas.push({
        name: 'NuevaVenta',params:{tipo:tipo=='2'?'p':'f'}
      }) 

})



}

//Revisa los checkbox seleccionados
const MetodoChequeo = () =>{
  nextTick(()=>{
const elementos = document.getElementsByName("checkbox")
elementos.forEach((elemento: HTMLElement)=>{

if(arreglo.elementos.find((e:any)=>e.id==elemento.id)){

const checkbox = elemento as HTMLInputElement
checkbox.checked = true
}
})

})
}



 
const MetodoBusquedaCliente = () =>{

  
MetodoCobros().then((e: any)=>{


  if(e==1){

const resultado = productosrespaldo.value.filter((e: typeof productosrespaldo.value)=>
e.cliente.match(ClienteBusqueda.value.toUpperCase()))
productos.value = resultado
if(notaspaginadas.value.length>=1000){
CambiarPaginacion(ultimoindex.value)
}else{
  CambiarPaginacion(0)
}
actualizartotal()
}
})


}

const recargar = () =>{

obtenercompleta().then((e: string)=>{
if(Number(e)>0){
    CambiarPaginacion(0)
}
})

}

const obtenercobros = async (e: number):Promise<number> =>{
const parametros = {
    body:JSON.stringify({iddocumento:e}),
    method:"post",
    headers:{"Content-type":"application/json"}
}
const consulta = await fetch("http://api.utp.hn/sg.php/sg.php?ac=cobros",parametros)
const respuesta = await consulta.json()

let total = 0
for (let x in respuesta) {
  total += Number(respuesta[x]["monto"])
}


return Number(respuesta.length>0?total:0)

}
const montoacumulado = ref("0.00")

const seleccionar = async (e: Event) =>{
const ID = e.target as HTMLInputElement
const monto = notaspaginadas.value.filter((elementos: any)=>{
  return elementos.id==ID.id
}).map((elementos:any)=>{
  return elementos.total
}).toString()
let cobro = await obtenercobros(Number(ID.id))

let objeto = {"id":ID.id,"monto":(Number(monto)-Number(cobro??0)).toFixed(2)}
if(arreglo.elementos.find((elemento:any)=>elemento.id==ID.id)){
    arreglo.elementos = arreglo.elementos.filter((e:any)=>e.id!=ID.id)

}else{

arreglo.elementos.push(objeto)

}
montoacumulado.value=arreglo.elementos.map((elemento: any)=>elemento.monto).reduce((anterior:number,actual:number)=>{
  return Number(anterior)+Number(actual)
},0).toFixed(2)

}





const cancelar = () =>{

arreglo.elementos=[]
nextTick(()=>{
montoacumulado.value =arreglo.elementos.map((elemento: any)=>elemento.monto).reduce((anterior:number,actual:number)=>{
  return Number(anterior)+Number(actual)
},0).toFixed(2)

const elementos = document.getElementsByName("checkbox")
elementos.forEach((elemento: HTMLElement)=>{
const checkbox = elemento as HTMLInputElement
checkbox.checked = false

})
})

}




const cobroindividual = async (i:string, c:string, m:number):Promise<number | undefined> =>{
 
 
const html = document.getElementById("formadepagomodelolocal") as HTMLSelectElement
const option = html.options[html.selectedIndex]

const parametros = {
    body:JSON.stringify({
      iddocumento:i,
      idcliente:c,
      montoacobrar:m,
      formadepago:option.text,
      formadepagoid:option.value
      }),
    method:"post",
    headers:{"Content-type":"application/json"}
}
const consulta = await fetch("http://api.utp.hn/sg.php/sg.php?ac=registrarcobro",parametros)
const respuesta = await consulta.json()
const resultado = respuesta["resultado"]
if(resultado=="1"){

arreglo.elementos=arreglo.elementos.filter((e:any)=>e.id!=i)
return 1
}else{
  return 0
}




}

const bordeado = ref(false)
const cobrar = async () =>{

bordeado.value=true
arreglo.elementos.forEach(async (e:any)=>{
const parametros = {
body:JSON.stringify({iddocumento:e["id"]}),
method:"post",
headers:{"Content-type":"application/json"}
}
const consulta = await fetch("http://api.utp.hn/sg.php/sg.php?ac=notadeventalineas",parametros)
const respuesta = await consulta.json()
const montoacobrar = e["monto"]

cobroindividual(e["id"],respuesta["0"]["clienteid"],montoacobrar).then(async (ee:any)=>{
if(ee==1 && arreglo.elementos.length==1){

funcionprimaria()
cancelar()
Swal.fire({
  position: 'top-end',
  icon: 'success',
  title: 'Trabajo finalizado',
  showConfirmButton: false,
  timer: 1500
})
bordeado.value=false
}
})

})



}

const seleccionartodo = () =>{




const elementos = document.getElementsByName("checkbox")
elementos.forEach(async (elemento: HTMLElement)=>{
const checkbox = elemento as HTMLInputElement

if(checkbox.checked==false){

checkbox.checked = true
const ID = elemento
const monto = notaspaginadas.value.filter((elementos: any)=>{
  return elementos.id==ID.id
}).map((elementos:any)=>{
  return elementos.total
}).toString()
let cobro = await obtenercobros(Number(ID.id))

let objeto = {"id":ID.id,"monto":(Number(monto)-Number(cobro??0)).toFixed(2)}
if(arreglo.elementos.find((elemento:any)=>elemento.id==ID.id)){
    arreglo.elementos = arreglo.elementos.filter((e:any)=>e.id!=ID.id)
}else{
arreglo.elementos.push(objeto)
}
montoacumulado.value =arreglo.elementos.map((elemento: any)=>elemento.monto).reduce((anterior:number,actual:number)=>{
  return Number(anterior)+Number(actual)
},0).toFixed(2)

}
})

}



return{
  imprimir: () => {
  window.print();
},
  formasdepago,
  bordeado,
  seleccionartodo,
cobrar,
cancelar,
montoacumulado,
...toRefs(arreglo),
seleccionar,  
recargar,
ultimoindex,
tipotitulo,
ClienteBusqueda,
MetodoBusquedaCliente,
abrirdocumento,
nuevaventa,
filtradoporestado,
cambiarestadocobros,
estadocobros,
idrecibido,
totalfinal,
obtenercompleta,
mostrar,
productoslineas,
cambiarestadodetalledelineas,
estadodetalledelineas,
estado,
total,
fecha1,fecha2,
CambiarPaginacion,
notaspaginadas,
idrecibidomultiple,
estadocobrosmultiples,

productos,
cambiarestado,
cambiarestadoanular,
obtenernotas,
id,
estadoanular,
busqueda,
hoy,
}
}

})
