
import archivero from '@/store'
interface Objeto {
totalcobradohoy?: string;
totalanteriores?: string;
corteacumulado?:string,
anterioresacumulado?:string,
hoyacumulado?:string,
ventaacumulada?:string,
totalcobrado?:string,
totalventa?:string,
pendientes?:string,
}

export default class Corte {
fecha1?:string;
fecha2?:string;
totalcobradohoy?: string|undefined
totalanteriores?: string|undefined
corteacumulado?:string|undefined
anterioresacumulado?:string|undefined
hoyacumulado?:string|undefined
ventaacumulada?:string|undefined
totalcobrado?:string|undefined
totalventa?: string|undefined
pendientes?: string | undefined
constructor(fecha1: string, fecha2:string){
this.fecha1 = fecha1
this.fecha2 = fecha2
}
obtenercobros = async():Promise<Objeto | undefined> =>{
const parametros = {
body:JSON.stringify({fecha1:this.fecha1,fecha2:this.fecha2}),
headers:{"content-type":"application/json"},
method: "post"
}
const consulta = await fetch("http://"+archivero.state.url+"/sg.php?ac=cobrospordia",parametros)
const respuesta = await consulta.json() 
const estado = consulta.statusText.toString()
if(estado=='OK'){
this.totalcobradohoy = (respuesta.filter((i: typeof respuesta)=> 
i.ventasfecha==this.fecha1 || i.ventasfecha==this.fecha2 )
.map((e: typeof respuesta)=>{
return e.monto
}).reduce((anterior:number, actual:number)=>{
return (Number(anterior)+Number(actual))
},0)).toFixed(2)
this.totalanteriores = (respuesta.filter((i: typeof respuesta)=> 
i.ventasfecha!==this.fecha1 && i.ventasfecha!==this.fecha2)
.map((e: typeof respuesta)=>{
return e.monto
}).reduce((anterior:number, actual:number)=>{
return (Number(anterior)+Number(actual))
},0)).toFixed(2)



const parametros2 = {
body:JSON.stringify({}),
headers:{"content-type":"application/json"},
method: "post"
}
const consulta2 = await fetch("http://"+archivero.state.url+"/sg.php?ac=cortespordia",parametros2)
const respuesta2 = await consulta2.json() 
const estado2 = consulta2.statusText.toString()

if(estado2=='OK'){
this.corteacumulado = (respuesta2.map((elemento:any)=> elemento.corte)
.reduce((anterior:number,actual:number)=>
Number(anterior)+Number(actual),0)).toFixed(2)

this.hoyacumulado = (respuesta2.map((elemento:any)=> elemento.hoy).reduce((anterior:number,actual:number)=>
Number(anterior)+Number(actual)
,0)).toFixed(2)
this.anterioresacumulado = (respuesta2.map((elemento:any)=> elemento.anteriores).reduce((anterior:number,actual:number)=>
Number(anterior)+Number(actual)
,0)).toFixed(2)
this.ventaacumulada = (respuesta2.map((elemento:any)=> elemento.venta).reduce((anterior:number,actual:number)=>
Number(anterior)+Number(actual)
,0)).toFixed(2)
}







const parametros3 = {
body:JSON.stringify({fecha1:this.fecha1,fecha2:this.fecha2, tipo:2}),
headers:{"content-type":"application/json"},
method: "post"
}
const consulta3 = await fetch("http://"+archivero.state.url+"/sg.php?ac=notasdeventa",parametros3)
const respuesta3 = await consulta3.json() 
const estado3 = consulta3.statusText.toString()

if(estado3=='OK'){
this.totalventa= (respuesta3.map((elemento:any)=> elemento.total)
.reduce((anterior:number,actual:number)=>
Number(anterior)+Number(actual),0)).toFixed(2)

}


const parametros4 = {
body:JSON.stringify({fecha1:this.fecha1,fecha2:this.fecha2}),
headers:{"content-type":"application/json"},
method: "post"
}
const consulta4 = await fetch("http://"+archivero.state.url+"/sg.php?ac=cobrospordiayventacxc",parametros4)
const respuesta4 = await consulta4.json() 

const estado = consulta4.statusText.toString()



const parametros5 = {
body:JSON.stringify({fecha1:this.fecha1,fecha2:this.fecha2}),
headers:{"content-type":"application/json"},
method: "post"
}
const consulta5 = await fetch("http://"+archivero.state.url+"/sg.php?ac=notasdeventacxc",parametros5)
const respuesta5 = await consulta5.json() 















if(estado=='OK'){
this.pendientes = 
(-Number((respuesta4.map((e:any)=>e.monto)).reduce((anterior:any,actual:any)=>Number(anterior)+Number(actual)))+
Number((respuesta5.map((e:any)=>e.total)).reduce((anterior:any,actual:any)=>Number(anterior)+Number(actual)))).toString()



}


this.totalcobrado = (Number(this.totalcobradohoy)+Number(this.totalanteriores)).toFixed(2)



let Retorno : Objeto ={
  totalcobradohoy: this.totalcobradohoy,
  totalanteriores: this.totalanteriores,
  corteacumulado:this.corteacumulado,
anterioresacumulado:this.anterioresacumulado,
hoyacumulado:this.hoyacumulado,
ventaacumulada:this.ventaacumulada,
totalcobrado:this.totalcobrado,
totalventa: this.totalventa,
pendientes: this.pendientes,
}

return Retorno


}
}






 
 

}





