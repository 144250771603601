import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Principal from '../views/principal.vue'
import Productos from '../views/productos.vue'
import Clientes from '../views/clientes.vue'
import Proveedores from '../views/proveedores.vue'
import NuevaVenta from '../views/nuevanotadeventa.vue'
import CuentasPorCobrar from '../views/cuentasporcobrar.vue'
import NuevaNotadeCompra from '../views/nuevanotadecompra.vue'
import Ventas from '../views/notasdeventa.vue'
import NotasdeCompra from '../views/notasdecompra.vue'
import Cortes  from '../views/cortes.vue'
import Categorias  from '../views/categorias.vue'
import Cobros  from '../views/cobros.vue'
import Corte  from '../views/corte.vue'
import Autorizaciones  from '../views/autorizaciones.vue'
import Sesion  from '../views/sesion.vue'
import CuentasDeBanco  from '../views/cuentasdebanco.vue'
import UnidadesyMedidas  from '../views/unidadesymedidas.vue'
import { computed } from '@vue/runtime-core'

const SESION = computed(()=>{
return  sessionStorage.getItem("sesion")
 })
  const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Principal',
    component: Principal,
    meta:{
      sesion: true,
   
    }
  },
  {
    path: '/UnidadesyMedidas',
    name: 'UnidadesyMedidas',
    component: UnidadesyMedidas,
    meta:{
      sesion: true,
   
    }
  },
  {
    path: '/Clientes',
    name: 'Clientes',
    component: Clientes,
    meta:{
      sesion: true,
  
    }
  },
  {
    path: '/CuentasDeBanco',
    name: 'CuentasDeBanco',
    component: CuentasDeBanco,
    meta:{
      sesion: true,
  
    }
  },
  {
    path: '/CuentasPorCobrar',
    name: 'CuentasPorCobrar',
    component: CuentasPorCobrar,
    meta:{
      sesion: true,
  
    }
  },
  {
    path: '/Proveedores',
    name: 'Proveedores',
    component: Proveedores,
    meta:{
      sesion: true,
  
    }
  },
  {
    path: '/Sesion',
    name: 'Sesion',
    component: Sesion,
    meta:{
      sesion: false,
    
    }
  },
  {
    path: '/corte',
    name: 'Corte',
    component: Corte,
    meta:{
      sesion: true,
    
    }
  },
  {
    path: '/cortes',
    name: 'Cortes',
    component: Cortes,
    meta:{
      sesion: true,
    
    }
  },
  {
    path: '/cobros',
    name: 'Cobros',
    component: Cobros,
    meta:{
      sesion: true,
    
    }
  },
  {
    path: '/Productos',
    name: 'Productos',
    component: Productos,
    meta:{
      sesion: true,
    
    }
  },
  {
    path: '/NuevaVenta/:tipo',
    name: 'NuevaVenta',
    component: NuevaVenta,
    meta:{
      sesion: true,
    
    }
  },
  
  {
    path: '/NuevaNotadeCompra',
    name: 'NuevaNotadeCompra',
    component: NuevaNotadeCompra,
    meta:{
      sesion: true,
    
    }
  },
  {
    path: '/Ventas/:tipo',
    name: 'Ventas',
    component: Ventas,
    meta:{
      sesion: true,
    
    },
    
  },
  {
    path: '/NotasdeCompra',
    name: 'NotasdeCompra',
    component: NotasdeCompra,
    meta:{
      sesion: true,
    
    }
  },
  {
    path: '/Categorias',
    name: 'Categorias',
    component: Categorias,
    meta:{
      sesion: true,
    
    }
   
  }
  ,
  {
    path: '/Autorizaciones',
    name: 'Autorizaciones',
    component: Autorizaciones,
    meta:{
      sesion: true,
    
    }
   
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.sesion)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!(SESION.value=="1")) {
      next({
        path: '/sesion',
        query: { redirect: to.fullPath }
      })
    } else {
      next()
    }
  } else {
    next() // make sure to always call next()!
  }
})
export default router
